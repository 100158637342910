import React from 'react'
import LicensePlateForm from './LicensePlateForm'
import { AsparkingLicensePlateDto } from '../../api'
import { useTranslation } from 'react-i18next'
import licensePlateService from '../../services/LicensePlateService'
import { cleanObject } from '../../utils/utils'
import { useHistory } from 'react-router-dom'

export const LicensePlateAddForm = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleSubmit = (data: AsparkingLicensePlateDto) => {
    licensePlateService
      .licensePlateCreate(cleanObject(data))
      .then(() => {
        history.push('/entry-and-parking/license-plate')
      })
      .catch()
  }

  return (
    <LicensePlateForm
      title={t('pages.licensePlate.create.title')}
      defaultValues={{
        licensePlate: '',
        firstName: '',
        surname: '',
        phone: '',
        valid: true,
        checkpointOneAllowed: false,
        checkpointTwoAllowed: false,
        checkpointThreeAllowed: false,
        note: '',
        flag: '',
        carrier: undefined,
        validFrom: new Date().toISOString(),
        validTo: new Date().toISOString(),
      }}
      onSubmit={handleSubmit}
    />
  )
}

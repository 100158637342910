import { isAutocompleteItem } from '../../utils/forms'
import { ConardAutocomplete } from '../shared/ConardAutocomplete'
import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutocompleteDto, CarrierShortDto } from '../../api'
import autocompleteService from '../../services/AutocompleteService'

interface CarrierAutocompleteProps {
  carrier: CarrierShortDto
  setCarrier: (carrier: CarrierShortDto) => void
  helperText?: string
  disabled?: boolean
}

export const CarrierAutocomplete: FC<CarrierAutocompleteProps> = ({
  carrier,
  setCarrier,
  helperText,
  disabled = false,
}) => {
  const { t } = useTranslation()
  const timerId = useRef<NodeJS.Timeout | null>(null)

  const [carriers, setCarriers] = useState<AutocompleteDto[] | undefined>(undefined)

  const onCarrierAutocompleteChange = async (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'CARRIER')
      setCarriers(response.data)
    }, 500)
  }

  const changeCarrier = (autocompleteDto: AutocompleteDto) => {
    setCarrier(autocompleteDto as CarrierShortDto)
  }

  return (
    <ConardAutocomplete
      onTextInputChange={onCarrierAutocompleteChange}
      label={t('pages.gateIn.form.carrier.label')}
      options={carriers?.map((c) => ({ id: c.id ?? -1, name: c.name ?? '' })) ?? []}
      value={carrier.id ? { id: carrier.id, name: carrier.name } : ''}
      onChange={(data) => {
        if (isAutocompleteItem(data)) {
          changeCarrier(data)
        } else if (data === null) {
          setCarrier({ id: undefined, name: '' })
        }
      }}
      helperText={helperText ?? ' '}
      disabled={disabled}
      disableClearable={false}
      clearOnBlur
      rounded
    />
  )
}

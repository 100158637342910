import React, { createContext, FC, useMemo } from 'react'
import brnoConfig from '../config/terminal/brnoConfig.json'
import mosnovConfig from '../config/terminal/mosnovConfig.json'
import developConfig from '../config/terminal/developConfig.json'

interface ConfigContextProps {
  entryAndParking: boolean
  customsClearance: boolean
  coordinates: {
    sectorLetterCount: number
    sectorMainLetterCount: number
    maxSectorColumn: number
    maxSectorRow: number
    maxSectorFloor: number
  }
  generalCarrierReference: boolean
  operatorView: {
    shipowner: boolean
    payload: boolean
    trainName: boolean
    lockdown: boolean
    weighted: boolean
    orderAsc: boolean
    importExportRepletedColumn: boolean
  }
  medlogBoard: boolean
  veronaTrain: boolean
  mscActive: boolean
  gateControl: boolean
  heavyTested: boolean
  conardTransitionForm: {
    showAllReferences: boolean
  }
  usersMosnov: boolean
  temporaryCustomer: boolean
  hasDriverArrivalSemaphore: boolean
  damageLogRequiredByKiosk: boolean
  instantGreenPass: boolean
  terminalLayout: boolean
  manualCoparnCreate: boolean
  adrReport: boolean
  storageAndDetentionDate: boolean
  englishExcelExport: boolean
  transitionPdfExport: boolean
  showNhmCodes: boolean
  eightContainersToTrain: boolean
  nightGateOut: boolean
  searchTransitionWithTime: boolean
  iluFilterAuditLogs: boolean
  gateInTrainViewMosnov: boolean
  vgm: boolean
  repairShop: boolean
  coparnDeletion: boolean
  creatableCarrierFromTransitionForm: boolean
}

export const ConfigContext = createContext<ConfigContextProps>({} as ConfigContextProps)

const ProvideConfig: FC = (props) => {
  const appConfig = useMemo(() => {
    switch (process.env.REACT_APP_CONARD_TERMINAL) {
      case 'BRNO':
        return brnoConfig
      case 'MOSNOV':
        return mosnovConfig
      default:
        return developConfig
    }
  }, [])

  return <ConfigContext.Provider value={appConfig}>{props.children}</ConfigContext.Provider>
}

export default ProvideConfig

import React, { ChangeEvent, useEffect, useState } from 'react'
import { AsparkingLicensePlateDto, AsparkingLicensePlateSearchDto, PageAsparkingLicensePlateDto } from '../../api'
import { useTranslation } from 'react-i18next'
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import ConardButton from '../../components/ConardButton'
import AddIcon from '@material-ui/icons/Add'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockIcon from '@material-ui/icons/Lock'
import { format } from 'date-fns'
import EditIcon from '@material-ui/icons/Edit'
import ConardProgressBar from '../../components/ConardProgressBar'
import { Order } from '../../enums/Order'
import { useHistory } from 'react-router-dom'
import licensePlateService from '../../services/LicensePlateService'
import { cleanObject } from '../../utils/utils'
import { LicensePlateFilter } from './LicensePlateFilter'

const useStyles = makeStyles((theme) =>
  createStyles({
    editTable: {
      width: '7vw',
    },
    typography: {
      width: '60vw',
    },
    newLicensePlateButton: {
      width: '15vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      margin: '20px',
      float: 'right',
    },
    filterButton: {
      textAlign: 'center',
      margin: '20px',
      float: 'left',
    },
    gridContainer: {
      marginTop: '8vh',
    },
    disabledTableRow: {
      backgroundColor: theme.palette.secondary.main,
      '& > .MuiTableCell-root': {
        color: theme.palette.grey.A200,
      },
    },
  })
)

interface HeadCell {
  id: keyof AsparkingLicensePlateDto
  label: string
}

export const LicensePlateListPage = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const headCells: HeadCell[] = [
    { id: 'licensePlate', label: t('entity.licensePlate.fields.licensePlate') },
    { id: 'carrier', label: t('entity.licensePlate.fields.carrier') },
    { id: 'firstName', label: t('entity.licensePlate.fields.firstName') },
    { id: 'surname', label: t('entity.licensePlate.fields.surname') },
    { id: 'phone', label: t('entity.licensePlate.fields.phone') },
    { id: 'valid', label: t('entity.licensePlate.fields.valid') },
    { id: 'checkpointOneAllowed', label: t('entity.licensePlate.fields.checkpointOne') },
    { id: 'checkpointTwoAllowed', label: t('entity.licensePlate.fields.checkpointTwo') },
    { id: 'checkpointThreeAllowed', label: t('entity.licensePlate.fields.checkpointThree') },
    { id: 'validFrom', label: t('entity.licensePlate.fields.validFrom') },
    { id: 'validTo', label: t('entity.licensePlate.fields.validTo') },
    { id: 'note', label: t('entity.licensePlate.fields.note') },
    { id: 'flag', label: t('entity.licensePlate.fields.flag') },
  ]

  const [licensePlates, setLicensePlates] = useState<PageAsparkingLicensePlateDto | null>()

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [order, setOrder] = useState<Order>(Order.Asc)
  const [orderBy, setOrderBy] = useState<keyof AsparkingLicensePlateDto>('licensePlate')
  const [searchDto, setSearchDto] = useState<AsparkingLicensePlateSearchDto | undefined>(undefined)

  const handleSort = (property: keyof AsparkingLicensePlateDto) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const filter = (data: AsparkingLicensePlateSearchDto | undefined) => {
    setSearchDto(data)
    search(data, true)
  }

  const handleEdit = (licensePlateId: number) => {
    history.push(`/entry-and-parking/license-plate/${licensePlateId}`)
  }

  const search = (data: AsparkingLicensePlateSearchDto | undefined, fromFilter: boolean) => {
    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    licensePlateService
      .licensePlateSearch(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setLicensePlates(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  useEffect(() => {
    search(searchDto, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {t('pages.entryAndParking.licensePlate.title')}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xs={12}>
          <div className={classes.newLicensePlateButton}>
            <ConardButton
              conardVariant="light"
              startIcon={<AddIcon />}
              onClick={() => history.push('/entry-and-parking/license-plate/create')}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>

        <Grid item xs={12}>
          <LicensePlateFilter filter={filter} />
        </Grid>

        <Grid item xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        component="th"
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {licensePlates?.content?.map((licensePlate) => (
                    <TableRow className={licensePlate?.valid ? '' : classes.disabledTableRow} key={licensePlate.id}>
                      <TableCell component="td" scope="row">
                        {licensePlate.licensePlate}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {licensePlate.carrier?.name}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {licensePlate?.firstName}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {licensePlate?.surname}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {licensePlate?.phone}
                      </TableCell>

                      {licensePlate.valid ? (
                        <>
                          <TableCell component="td" scope="row">
                            {licensePlate.valid ? <DoneOutlineIcon color="primary" /> : <CancelIcon color="primary" />}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {licensePlate.checkpointOneAllowed ? (
                              <LockOpenIcon color="primary" />
                            ) : (
                              <LockIcon color="primary" />
                            )}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {licensePlate.checkpointTwoAllowed ? (
                              <LockOpenIcon color="primary" />
                            ) : (
                              <LockIcon color="primary" />
                            )}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {licensePlate.checkpointThreeAllowed ? (
                              <LockOpenIcon color="primary" />
                            ) : (
                              <LockIcon color="primary" />
                            )}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell component="td" scope="row">
                            {licensePlate.valid ? <DoneOutlineIcon /> : <CancelIcon />}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {licensePlate.checkpointOneAllowed ? <LockOpenIcon /> : <LockIcon />}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {licensePlate.checkpointTwoAllowed ? <LockOpenIcon /> : <LockIcon />}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {licensePlate.checkpointThreeAllowed ? <LockOpenIcon /> : <LockIcon />}
                          </TableCell>
                        </>
                      )}

                      <TableCell component="td" scope="row">
                        {licensePlate.validFrom ? format(new Date(licensePlate.validFrom), 'dd.MM.yyyy') : ''}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {licensePlate.validTo ? format(new Date(licensePlate.validTo), 'dd.MM.yyyy') : ''}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {licensePlate.note ?? ''}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {licensePlate.flag ?? ''}
                      </TableCell>
                      <TableCell className={classes.editTable}>
                        <ConardButton
                          conardVariant="light"
                          startIcon={<EditIcon />}
                          onClick={() => handleEdit(licensePlate.id ?? -1)}
                        >
                          {t('any.buttons.edit')}
                        </ConardButton>
                      </TableCell>
                    </TableRow>
                  ))}

                  {licensePlates?.content?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="td" scope="row" colSpan={13} align="center">
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!licensePlates} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={licensePlates?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.newLicensePlateButton}>
            <ConardButton
              conardVariant="light"
              startIcon={<AddIcon />}
              onClick={() => history.push('/entry-and-parking/license-plate/create')}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

import React, { FC, useRef, useState } from 'react'
import { createStyles, Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ConardTextField from '../../components/ConardTextField'
import { makeStyles } from '@material-ui/core/styles'
import ConardButton from '../../components/ConardButton'
import ConardSwitch from '../../components/ConardSwitch'
import { FieldName } from 'react-hook-form/dist/types/fields'
import { AsparkingLicensePlateDto, AutocompleteDto } from '../../api'
import ConardDatePicker from '../../components/ConardDatePicker'
import { RHFAutocomplete } from '../../components/shared/rhf/RHFAutocomplete'
import autocompleteService from '../../services/AutocompleteService'

const useStyles = makeStyles((theme) =>
  createStyles({
    switchText: {
      marginLeft: 35,
      fontSize: 12,
    },
    typography: {
      width: '60vw',
    },
    button: {
      width: '15vw',
      minWidth: '200px',
      textAlign: 'center',
      margin: 'auto',
    },
    container: {
      marginTop: '10vh',
      width: '50vw',
      minWidth: '300px',
      margin: 'auto',
    },
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '58.5px',
      margin: 'auto',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      marginTop: '-18px',
      '&:hover': {
        borderColor: 'black',
      },
    },
  })
)

interface LicensePlateFormProps {
  defaultValues: AsparkingLicensePlateDto
  onSubmit: (data: AsparkingLicensePlateDto) => void
  title: string
}

const LicensePlateForm: FC<LicensePlateFormProps> = ({ defaultValues, onSubmit, title }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const timerId = useRef<NodeJS.Timeout | null>(null)

  const [carriers, setCarriers] = useState<AutocompleteDto[] | undefined>(undefined)
  const [valid, setValid] = useState<boolean>(defaultValues.valid)
  const [checkpointOneAllowed, setCheckpointOneAllowed] = useState<boolean>(defaultValues.checkpointOneAllowed)
  const [checkpointTwoAllowed, setCheckpointTwoAllowed] = useState<boolean>(defaultValues.checkpointTwoAllowed)
  const [checkpointThreeAllowed, setCheckpointThreeAllowed] = useState<boolean>(defaultValues.checkpointThreeAllowed)

  const yupSchema = yup.object().shape({
    licensePlate: yup
      .string()
      .required(t('pages.licensePlate.validation.licensePlate.required'))
      .min(5, t('pages.gateIn.validations.semitrailerLicencePlate.min', { min: 5 }))
      .max(10, t('pages.gateIn.validations.semitrailerLicencePlate.max', { max: 10 })),
  })

  const formMethods = useForm<AsparkingLicensePlateDto>({
    defaultValues: defaultValues,
    resolver: yupResolver(yupSchema),
  })

  const changeSwitch = (field: FieldName<AsparkingLicensePlateDto>, newValue: boolean) => {
    formMethods.control.setValue(field, newValue)
  }

  const onChangeCarrier = async (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'CARRIER')
      setCarriers(response.data)
    }, 400)
  }

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {title}
      </Typography>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Grid
            container
            className={classes.container}
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={4}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="firstName"
                name="firstName"
                type="text"
                label={t('entity.licensePlate.fields.firstName')}
                fullWidth
                error={!!formMethods.errors.firstName}
                helperText={formMethods.errors.firstName ? formMethods.errors.firstName.message : ' '}
                control={formMethods.control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="surname"
                name="surname"
                type="text"
                label={t('entity.licensePlate.fields.surname')}
                fullWidth
                error={!!formMethods.errors.surname}
                helperText={formMethods.errors.surname ? formMethods.errors.surname.message : ' '}
                control={formMethods.control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="licensePlate"
                name="licensePlate"
                type="text"
                label={t('entity.licensePlate.fields.licensePlate')}
                fullWidth
                error={!!formMethods.errors.licensePlate}
                helperText={formMethods.errors.licensePlate ? formMethods.errors.licensePlate.message : ' '}
                control={formMethods.control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="phone"
                name="phone"
                type="text"
                label={t('entity.licensePlate.fields.phone')}
                fullWidth
                error={!!formMethods.errors.phone}
                helperText={formMethods.errors.phone ? formMethods.errors.phone.message : ' '}
                control={formMethods.control}
              />
            </Grid>
            <Grid item xs={12}>
              <RHFAutocomplete
                name="carrier"
                label={t('entity.licensePlate.fields.carrier')}
                options={carriers?.map((c) => ({ id: c.id ?? -1, name: c.name ?? '' })) ?? []}
                onChange={(data) => {
                  if (typeof data === 'string') {
                    formMethods.control.setValue('carrier', null)
                  }
                }}
                onTextInputChange={onChangeCarrier}
                helperText={formMethods.errors.carrier ? formMethods.errors.carrier.id?.message : ' '}
                disableClearable={false}
                rounded
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardDatePicker
                name="validFrom"
                control={formMethods.control}
                label={t('entity.licensePlate.fields.validFrom')}
                error={!!formMethods.errors.validFrom}
                helperText={formMethods.errors.validFrom ? formMethods.errors.validFrom.message : ' '}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardDatePicker
                name="validTo"
                control={formMethods.control}
                label={t('entity.licensePlate.fields.validTo')}
                error={!!formMethods.errors.validTo}
                helperText={formMethods.errors.validTo ? formMethods.errors.validTo.message : ' '}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="note"
                name="note"
                type="text"
                label={t('entity.licensePlate.fields.note')}
                fullWidth
                error={!!formMethods.errors.note}
                helperText={formMethods.errors.note ? formMethods.errors.note.message : ' '}
                control={formMethods.control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="flag"
                name="flag"
                type="text"
                label={t('entity.licensePlate.fields.flag')}
                fullWidth
                error={!!formMethods.errors.flag}
                helperText={formMethods.errors.flag ? formMethods.errors.flag.message : ' '}
                control={formMethods.control}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} />
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardSwitch
                id="valid"
                name="valid"
                checked={valid}
                onChange={(newValue) => {
                  changeSwitch('valid', newValue)
                  setValid(newValue)
                }}
                control={formMethods.control}
                checkedLabel={t('pages.licensePlate.table.valid')}
                uncheckedLabel={t('pages.licensePlate.table.invalid')}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} />
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <Typography className={classes.switchText}>{t('pages.licensePlate.table.checkpointOne')}</Typography>
              <ConardSwitch
                id="checkpointOneAllowed"
                name="checkpointOneAllowed"
                checked={checkpointOneAllowed}
                onChange={(newValue) => {
                  changeSwitch('checkpointOneAllowed', newValue)
                  setCheckpointOneAllowed(newValue)
                }}
                control={formMethods.control}
                checkedLabel={t('pages.licensePlate.table.allowed')}
                uncheckedLabel={t('pages.licensePlate.table.notAllowed')}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <Typography className={classes.switchText}>{t('pages.licensePlate.table.checkpointTwo')}</Typography>
              <ConardSwitch
                id="checkpointTwoAllowed"
                name="checkpointTwoAllowed"
                checked={checkpointTwoAllowed}
                onChange={(newValue) => {
                  changeSwitch('checkpointTwoAllowed', newValue)
                  setCheckpointTwoAllowed(newValue)
                }}
                control={formMethods.control}
                checkedLabel={t('pages.licensePlate.table.allowed')}
                uncheckedLabel={t('pages.licensePlate.table.notAllowed')}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <Typography className={classes.switchText}>{t('pages.licensePlate.table.checkpointThree')}</Typography>
              <ConardSwitch
                id="checkpointThreeAllowed"
                name="checkpointThreeAllowed"
                checked={checkpointThreeAllowed}
                onChange={(newValue) => {
                  changeSwitch('checkpointThreeAllowed', newValue)
                  setCheckpointThreeAllowed(newValue)
                }}
                control={formMethods.control}
                checkedLabel={t('pages.licensePlate.table.allowed')}
                uncheckedLabel={t('pages.licensePlate.table.notAllowed')}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={6} sm={12} xs={12} />
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} />
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" type="submit">
                  {t('any.buttons.save')}
                </ConardButton>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                  {t('any.buttons.back')}
                </ConardButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  )
}

export default LicensePlateForm

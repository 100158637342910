import React from 'react'
import LicensePlateForm from './LicensePlateForm'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import licensePlateService from '../../services/LicensePlateService'
import { AsparkingLicensePlateDto } from '../../api'
import { cleanObject } from '../../utils/utils'

interface UrlParamTypes {
  id?: string
}

export const LicensePlateEditForm = () => {
  const { id } = useParams<UrlParamTypes>()
  const { t } = useTranslation()
  const history = useHistory()

  const [loaded, setLoaded] = useState(false)
  const [licensePlate, setLicensePlate] = useState<AsparkingLicensePlateDto>()

  useEffect(() => {
    if (id !== undefined) {
      licensePlateService
        .licensePlateFindById(Number.parseInt(id))
        .then((response) => {
          setLicensePlate(response.data)
        })
        .finally(() => {
          setLoaded(true)
        })
    }
  }, [id])

  const handleSubmit = (data: AsparkingLicensePlateDto) => {
    licensePlateService
      .licensePlateUpdate(licensePlate?.id ?? -1, cleanObject(data))
      .then(() => {
        history.push('/entry-and-parking/license-plate')
      })
      .catch()
  }

  return (
    <>
      {loaded && (
        <LicensePlateForm
          title={t('pages.licensePlate.update.title')}
          defaultValues={{
            licensePlate: licensePlate?.licensePlate ?? '',
            firstName: licensePlate?.firstName ?? '',
            surname: licensePlate?.surname ?? '',
            phone: licensePlate?.phone ?? '',
            valid: licensePlate?.valid ?? false,
            checkpointOneAllowed: licensePlate?.checkpointOneAllowed ?? false,
            checkpointTwoAllowed: licensePlate?.checkpointTwoAllowed ?? false,
            checkpointThreeAllowed: licensePlate?.checkpointThreeAllowed ?? false,
            note: licensePlate?.note ?? '',
            flag: licensePlate?.flag ?? '',
            carrier: licensePlate?.carrier ?? undefined,
            validFrom: licensePlate?.validFrom ?? new Date().toISOString(),
            validTo: licensePlate?.validTo ?? new Date().toISOString(),
          }}
          onSubmit={handleSubmit}
        />
      )}
    </>
  )
}

import React, { FC, useRef, useState } from 'react'
import { AsparkingLicensePlateSearchDto, AutocompleteDto } from '../../api'
import { Grid, MenuItem } from '@material-ui/core'
import ConardTextField from '../../components/ConardTextField'
import ConardSelect from '../../components/ConardSelect'
import ConardButton from '../../components/ConardButton'
import { FormProvider, useForm } from 'react-hook-form'
import { RHFAutocomplete } from '../../components/shared/rhf/RHFAutocomplete'
import autocompleteService from '../../services/AutocompleteService'
import { useTranslation } from 'react-i18next'

interface LicensePlateFilterProps {
  filter: (data: AsparkingLicensePlateSearchDto) => void
}

export const LicensePlateFilter: FC<LicensePlateFilterProps> = ({ filter }) => {
  const { t } = useTranslation()
  const timerId = useRef<NodeJS.Timeout | null>(null)

  const formMethods = useForm<AsparkingLicensePlateSearchDto>()

  const [carriers, setCarriers] = useState<AutocompleteDto[] | undefined>(undefined)

  const [selectValidity, setSelectValidity] = useState('NOT-SELECTED')
  const [selectCheckpointOne, setSelectCheckpointOne] = useState('NOT-SELECTED')
  const [selectCheckpointTwo, setSelectCheckpointTwo] = useState('NOT-SELECTED')
  const [selectCheckpointThree, setSelectCheckpointThree] = useState('NOT-SELECTED')

  const onChangeCarrier = async (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'CARRIER')
      setCarriers(response.data)
    }, 400)
  }

  const onSubmit = (data: AsparkingLicensePlateSearchDto) => {
    const newData = {
      ...data,
      carrierId: !Number.isNaN(Number(data.carrierId)) ? data.carrierId : undefined,
    }
    filter(newData)
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
            <ConardTextField
              id="licensePlate"
              name="licensePlate"
              defaultValue=""
              type="text"
              label={t('entity.licensePlate.fields.licensePlate')}
              control={formMethods.control}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
            <RHFAutocomplete
              name="carrierId"
              label={t('entity.licensePlate.fields.carrier')}
              options={carriers?.map((c) => ({ id: c.id ?? -1, name: c.name ?? '' })) ?? []}
              onTextInputChange={onChangeCarrier}
              clearOnBlur
              disableClearable={false}
              rounded
              singleId
            />
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
            <ConardTextField
              id="firstName"
              name="firstName"
              defaultValue=""
              type="text"
              label={t('entity.licensePlate.fields.firstName')}
              control={formMethods.control}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
            <ConardTextField
              id="surname"
              name="surname"
              defaultValue=""
              type="text"
              label={t('entity.licensePlate.fields.surname')}
              control={formMethods.control}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
            <ConardSelect
              id="valid"
              name="valid"
              label={t('entity.licensePlate.fields.valid')}
              control={formMethods.control}
              value={selectValidity}
              onChange={(event) => setSelectValidity(event.target.value)}
              defaultValue="NOT-SELECTED"
            >
              <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
              <MenuItem value="true">{t('pages.licensePlate.table.valid')}</MenuItem>
              <MenuItem value="false">{t('pages.licensePlate.table.invalid')}</MenuItem>
            </ConardSelect>
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
            <ConardSelect
              id="checkpointOneAllowed"
              name="checkpointOneAllowed"
              label={t('pages.licensePlate.table.checkpointOne')}
              defaultValue="NOT-SELECTED"
              value={selectCheckpointOne}
              onChange={(event) => setSelectCheckpointOne(event.target.value)}
              control={formMethods.control}
            >
              <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
              <MenuItem value="true">{t('pages.common.select.true')}</MenuItem>
              <MenuItem value="false">{t('pages.common.select.false')}</MenuItem>
            </ConardSelect>
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
            <ConardSelect
              id="checkpointTwoAllowed"
              name="checkpointTwoAllowed"
              label={t('pages.licensePlate.table.checkpointTwo')}
              defaultValue="NOT-SELECTED"
              value={selectCheckpointTwo}
              onChange={(event) => setSelectCheckpointTwo(event.target.value)}
              control={formMethods.control}
            >
              <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
              <MenuItem value="true">{t('pages.common.select.true')}</MenuItem>
              <MenuItem value="false">{t('pages.common.select.false')}</MenuItem>
            </ConardSelect>
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
            <ConardSelect
              id="checkpointThreeAllowed"
              name="checkpointThreeAllowed"
              label={t('pages.licensePlate.table.checkpointThree')}
              defaultValue="NOT-SELECTED"
              value={selectCheckpointThree}
              onChange={(event) => setSelectCheckpointThree(event.target.value)}
              control={formMethods.control}
            >
              <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
              <MenuItem value="true">{t('pages.common.select.true')}</MenuItem>
              <MenuItem value="false">{t('pages.common.select.false')}</MenuItem>
            </ConardSelect>
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={6} xs={12} />
          <Grid item xl={6} lg={6} />
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <div>
              <ConardButton conardVariant="light" type="submit">
                {t('any.buttons.filter')}
              </ConardButton>
            </div>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

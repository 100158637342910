import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Order } from '../../enums/Order'
import ConardButton from '../../components/ConardButton'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { cleanObject } from '../../utils/utils'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import CarrierLicensePlateFilter from './CarrierLicensePlateFilter'
import { AsparkingLicensePlateDto, AsparkingLicensePlateSearchDto, PageAsparkingLicensePlateDto } from '../../api'
import licensePlateService from '../../services/LicensePlateService'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockIcon from '@material-ui/icons/Lock'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import { format } from 'date-fns'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ConardProgressBar from '../../components/ConardProgressBar'

const useStyles = makeStyles((theme) =>
  createStyles({
    editTable: {
      width: '7vw',
    },
    typography: {
      width: '60vw',
    },
    newLicensePlateButton: {
      width: '15vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      margin: '20px',
      float: 'right',
    },
    filterButton: {
      textAlign: 'center',
      margin: '20px',
      float: 'left',
    },
    gridContainer: {
      marginTop: '8vh',
    },
    disabledTableRow: {
      backgroundColor: theme.palette.secondary.main,
      '& > .MuiTableCell-root': {
        color: theme.palette.grey.A200,
      },
    },
  })
)

interface UrlParamTypes {
  id?: string
}

interface HeadCell {
  id: keyof AsparkingLicensePlateDto
  label: string
}

const CarrierLicensePlateListPage: FC = () => {
  const { id } = useParams<UrlParamTypes>()
  const { t } = useTranslation()
  const history = useHistory()

  const [licensePlates, setLicensePlates] = useState<PageAsparkingLicensePlateDto | null>()

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [order, setOrder] = useState<Order>(Order.Asc)
  const [orderBy, setOrderBy] = useState<keyof AsparkingLicensePlateDto>('licensePlate')
  const [showFilter, setShowFilter] = useState(true)
  const [searchDto, setSearchDto] = useState<AsparkingLicensePlateSearchDto | undefined>(undefined)

  const classes = useStyles()

  const headCells: HeadCell[] = [
    { id: 'licensePlate', label: t('entity.licensePlate.fields.licensePlate') },
    { id: 'firstName', label: t('entity.licensePlate.fields.firstName') },
    { id: 'surname', label: t('entity.licensePlate.fields.surname') },
    { id: 'phone', label: t('entity.licensePlate.fields.phone') },
    { id: 'valid', label: t('entity.licensePlate.fields.valid') },
    { id: 'checkpointOneAllowed', label: t('entity.licensePlate.fields.checkpointOne') },
    { id: 'checkpointTwoAllowed', label: t('entity.licensePlate.fields.checkpointTwo') },
    { id: 'checkpointThreeAllowed', label: t('entity.licensePlate.fields.checkpointThree') },
    { id: 'validFrom', label: t('entity.licensePlate.fields.validFrom') },
    { id: 'validTo', label: t('entity.licensePlate.fields.validTo') },
    { id: 'note', label: t('entity.licensePlate.fields.note') },
    { id: 'flag', label: t('entity.licensePlate.fields.flag') },
  ]

  useEffect(() => {
    search(searchDto, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: keyof AsparkingLicensePlateDto) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const handleFilter = () => {
    setShowFilter(!showFilter)
  }

  const searchFiltered = (data: AsparkingLicensePlateSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: AsparkingLicensePlateSearchDto | undefined, fromFilter: boolean) => {
    let newSearchDto
    if (id !== undefined) {
      newSearchDto = {
        ...data,
        carrierId: Number.parseInt(id),
      }
    }
    setSearchDto(newSearchDto)

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    licensePlateService.licensePlateSearch(page, pageSize, currentSort, cleanObject(newSearchDto)).then((response) => {
      setLicensePlates(response.data)
      if (fromFilter) {
        setPage(0)
      }
    })
  }

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {t('pages.entryAndParking.licensePlate.title')}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
          <div className={classes.filterButton}>
            <ConardButton
              conardVariant="transparent"
              startIcon={<FilterListIcon />}
              endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              onClick={handleFilter}
            >
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
        <Grid item xl={10} lg={9} md={6} sm={6} xs={6} style={{ float: 'right' }}>
          <div className={classes.newLicensePlateButton}>
            <ConardButton
              conardVariant="light"
              startIcon={<AddIcon />}
              onClick={() => history.push(`/entry-and-parking/carrier/${id}/license-plate/create`)}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
          <div className={classes.newLicensePlateButton}>
            <ConardButton
              conardVariant="light"
              startIcon={<ArrowBackIcon fontSize="small" color="secondary" />}
              onClick={() => {
                history.push('/entry-and-parking/carrier')
              }}
            >
              {t('any.buttons.back')}
            </ConardButton>
          </div>
        </Grid>

        {showFilter && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CarrierLicensePlateFilter licensePlatesFilter={searchFiltered} />
          </Grid>
        )}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {licensePlates?.content?.map((licensePlate) => (
                    <TableRow className={licensePlate?.valid ? '' : classes.disabledTableRow} key={licensePlate.id}>
                      <TableCell component="th" scope="row">
                        {licensePlate.licensePlate}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {licensePlate?.firstName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {licensePlate?.surname}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {licensePlate?.phone}
                      </TableCell>

                      {licensePlate.valid ? (
                        <>
                          <TableCell component="th" scope="row">
                            {licensePlate.valid ? <DoneOutlineIcon color="primary" /> : <CancelIcon color="primary" />}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {licensePlate.checkpointOneAllowed ? (
                              <LockOpenIcon color="primary" />
                            ) : (
                              <LockIcon color="primary" />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {licensePlate.checkpointTwoAllowed ? (
                              <LockOpenIcon color="primary" />
                            ) : (
                              <LockIcon color="primary" />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {licensePlate.checkpointThreeAllowed ? (
                              <LockOpenIcon color="primary" />
                            ) : (
                              <LockIcon color="primary" />
                            )}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell component="th" scope="row">
                            {licensePlate.valid ? <DoneOutlineIcon /> : <CancelIcon />}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {licensePlate.checkpointOneAllowed ? <LockOpenIcon /> : <LockIcon />}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {licensePlate.checkpointTwoAllowed ? <LockOpenIcon /> : <LockIcon />}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {licensePlate.checkpointThreeAllowed ? <LockOpenIcon /> : <LockIcon />}
                          </TableCell>
                        </>
                      )}

                      <TableCell component="th" scope="row">
                        {format(new Date(licensePlate.validFrom ?? new Date()), 'dd.MM.yyyy')}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {format(new Date(licensePlate.validTo ?? new Date()), 'dd.MM.yyyy')}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {licensePlate.note ?? ''}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {licensePlate.flag ?? ''}
                      </TableCell>
                      <TableCell className={classes.editTable}>
                        <ConardButton
                          conardVariant="light"
                          startIcon={<EditIcon />}
                          onClick={() =>
                            history.push(`/entry-and-parking/carrier/${id}/license-plate/update/${licensePlate.id}`)
                          }
                        >
                          {t('any.buttons.edit')}
                        </ConardButton>
                      </TableCell>
                    </TableRow>
                  ))}

                  {licensePlates?.content?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={13} align="center">
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!licensePlates} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={licensePlates?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.newLicensePlateButton}>
            <ConardButton
              conardVariant="light"
              startIcon={<AddIcon />}
              onClick={() => history.push(`/entry-and-parking/carrier/${id}/license-plate/create`)}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default CarrierLicensePlateListPage

import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ConardAutocomplete, ConardAutocompletePropsUnited } from '../ConardAutocomplete'
import { isAutocompleteItem } from '../../../utils/forms'

type Props = Omit<ConardAutocompletePropsUnited, 'value' | 'onChange'> &
  Partial<Pick<ConardAutocompletePropsUnited, 'onChange'>> & {
    name: string
    singleId?: boolean
  }

export const RHFAutocomplete: FC<Props> = ({
  name,
  disabled,
  label,
  options,
  onChange: onChangeProps,
  helperText,
  singleId = false,
  ...rest
}) => {
  const { control, errors } = useFormContext()
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ value, onChange }) => {
        const selectedOption = singleId ? options.find((opt) => opt.id === value) || '' : value

        return (
          <ConardAutocomplete
            disabled={disabled}
            label={label}
            options={options}
            value={selectedOption}
            onChange={(data) => {
              const newValue = singleId && isAutocompleteItem(data) ? data.id.toString() : data
              onChange(newValue)
              onChangeProps?.(newValue)
            }}
            helperText={errors?.[name]?.message ?? helperText}
            {...rest}
          />
        )
      }}
    />
  )
}

import { yupResolver } from '@hookform/resolvers/yup'
import {
  alpha,
  Chip,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import React, { ChangeEvent, createRef, FC, useContext, useEffect, useState } from 'react'
import { Controller, FieldError, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  AdrRidCodeDto,
  AutocompleteDto,
  CarrierShortDto,
  CompanyDto,
  ContainerDto,
  ContainerDtoContainerTypeEnum,
  ContainerDtoSemitrailerTypeEnum,
  ContainerDtoShippingContainerTypeEnum,
  ContainerStateDtoStatusEnum,
  DriverShortDto,
  ExtraDto,
  LicensePlateDto,
  NhmCodeDto,
  NotificationDto,
  NotificationDtoNotificationTypeEnum,
  ProcessedFileDto,
  ShipownerDto,
  TrainDtoTrainTypeEnum,
  TransitionDto,
  TransitionDtoContainerStateEnum,
  TransitionDtoDirectionEnum,
  TransitionDtoImportExportEnum,
  TransitionDtoQualityTypeEnum,
  TransitionDtoReceiptMethodEnum,
  TransitionMrnDto,
  TransitionSearchDtoContainerStateEnum,
  TransitionSearchDtoReceiptMethodEnum,
  UserDtoRoleEnum,
} from '../../api'
import { Teu } from '../../enums/Teu'
import { TransitionMode } from '../../enums/TransitionMode'
import companyService from '../../services/CompanyService'
import gateInService from '../../services/GateInService'
import gateOutService from '../../services/GateOutService'
import transitionService from '../../services/TransitionService'
import { cleanObject, cleanShippingContainerType, setImportExport } from '../../utils/utils'
import ConardButton from '../ConardButton'
import ConardDateTimePicker from '../ConardDateTimePicker'
import ConardPhotographer from '../ConardPhotographer'
import ConardSelect from '../ConardSelect'
import ConardSwitch from '../ConardSwitch'
import ConardTextField from '../ConardTextField'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux/store'
import {
  addIluCode,
  setComingFromDamageLog,
  setTransition,
  setTransitionFormDisabled,
  setTransitionPositionDisabled,
  setTransitionSaveDisabled,
  transitionInitialState,
} from '../../redux/transition/transitionSlice'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { TransitionFormValidationScheme } from '../../validationSchemes/TransitionFormValidationScheme'
import shipownerService from '../../services/ShipownerService'
import { TarraWeight } from '../../enums/TarraWeight'
import { addDays, format } from 'date-fns'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
import asparkingSemaphoreControlService from '../../services/AsparkingSemaphoreControlService'
import notificationService from '../../services/NotificationService'
import { SemaphoreMode } from '../../enums/SemaphoreMode'
import { AsparkingCheckpoint } from '../../enums/AsparkingCheckpoint'
import { toast } from 'react-toastify'
import NhmService from '../../services/NhmService'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import ConardTransitionCustomsInfoBox from './ConardTransitionCustomsInfoBox'
import RidService from '../../services/RidService'
import ConardTooltip from '../ConardTooltip'
import ProcessedFileService from '../../services/ProcessedFileService'
import processedFileService from '../../services/ProcessedFileService'
import { ContainerDamagedIcon } from '../../assets/icons/ContainerDamagedIcon'
import { SemitrailerDamagedIcon } from '../../assets/icons/SemitrailerDamagedIcon'
import { ConfigContext } from '../../context/ConfigContext'
import TransitionWrongIluDialog from '../dialogs/TransitionWrongIluDialog'
import { useConardAuth } from '../../hooks/useConardAuth'
import TransitionDeleteDialog from '../dialogs/TransitionDeleteDialog'
import ConardCheckbox from '../ConardCheckbox'
import ConardTextAutocomplete from '../ConardTextAutocomplete'
import driverService from '../../services/DriverService'
import ConardInstantGreenBox from './ConardInstantGreenPassBox'
import { ButtonsForTransitionForm } from './ButtonsForTransitionForm'
import { AddAPhoto } from '@material-ui/icons'
import uploadedFileService from '../../services/UploadedFileService'
import { SignetPhotoDetail } from '../dialogs/SignetPhotoDetail'
import { circularProgressClasses } from '@mui/material'
import { ConardStore } from './ConardStore'
import { ConardDamageLogBox } from './ConardDamageLogBox'
import transitionExtraService from '../../services/TransitionExtraService'
import ConardDatePicker from '../ConardDatePicker'
import { ConardTransitionFormPdfExportBox } from './ConardTransitionFormPdfExportBox'
import { changeSignetPhoto, setInitialSignetPhoto, SignetPhotoState } from '../../redux/transition/signetPhotoSlice'
import { ConardTransitionFormVgmBox } from './ConardTransitionFormVgmBox'
import { ConardRepairShopBox } from './ConardRepairShopBox'
import { CarrierAutocomplete } from '../transition/CarrierAutocomplete'

const useStyles = makeStyles((theme) =>
  createStyles({
    typographyLabel: {
      width: '50vw',
    },
    container: {
      width: '100%',
      marginTop: '3vh',
      marginBottom: '6vh',
      [theme.breakpoints.only('xl')]: {
        '& > .MuiGrid-item': {
          padding: '12px',
        },
      },
    },
    damagePanelContainer: {
      margin: '3rem 0',
      backgroundColor: alpha(theme.palette.secondary.dark, 0.3),
      borderRadius: '8px',
      textAlign: 'center',
      minWidth: '290px',
      padding: '20px 0px 20px 0px',
    },
    damageContent: {
      marginBottom: '20px',
    },
    damageIcon: {
      fontSize: '110px',
      color: theme.palette.primary.main,
    },
    controls: {
      [theme.breakpoints.up('sm')]: {
        left: '12vw',
      },
      [theme.breakpoints.down('xs')]: {
        left: '0px',
      },
      position: 'fixed',
      bottom: 0,
      width: '88vw',
      backgroundColor: theme.palette.secondary.main,
      zIndex: 100,
    },
    submitButton: {
      width: '14vw',
      minWidth: '200px',
      textAlign: 'center',
      marginTop: '2vh',
      marginBottom: '2vh',
      marginLeft: '5vw',
    },
    submitAndStore: {
      whiteSpace: 'nowrap',
    },
    submissionGrid: {
      height: '50vh',
    },
    manipulationButton: {
      [theme.breakpoints.only('xl')]: {
        height: '5vh',
      },
      margin: 'auto',
      marginTop: '-20px',
    },
    formGroup: {
      justifyContent: 'space-between',
    },
    autocomplete: {
      width: '100%',
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(34px, -6px) scale(0.75)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          marginLeft: '20px',
        },
      },
      '& .MuiAutocomplete-endAdornment': {
        right: '7px',
      },
    },
    autocompleteOption: {
      width: '100%',
    },
    inputAutocomplete: {
      borderRadius: 30,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.main,
    },
    nhmInputAutocomplete: {
      borderRadius: 30,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.main,
      display: 'table',
      paddingRight: 60,
      height: 50,
      maxHeight: 200,
    },
    nhmButton: {
      height: '56px',
      marginBottom: '19.91px',
    },
    chipLabel: {
      maxWidth: '19vw',
    },
    codecoDialog: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    codecoDialogIcon: {
      fontSize: 150,
    },
    codecoButton: {
      marginBottom: '10px',
    },
    signetPhotoWrapper: {
      margin: '1rem 4rem',
      cursor: 'pointer',
    },
    signetPhotoDiv: {
      display: 'flex',
      border: 'dashed 2px' + theme.palette.primary.main,
      height: '150px',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.primary.main,
    },
    signetPhoto: {
      width: '100%',
      transition: 'filter 0.2s',
      '&:hover': {
        filter: 'brightness(80%)',
      },
    },
    circularProgress: {
      [`& .${circularProgressClasses.circle}`]: {
        strokeLinecap: 'round',
      },
    },
    datepickerLabel: {
      marginLeft: '20px',
      color: 'gray',
      fontSize: 'small',
    },
    colorDiv: {
      width: '10px',
      height: '10px',
      borderRadius: '100%',
      marginLeft: '10px',
    },
    companySelectItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
)

interface ConardTransitionFormProps {
  containerType: ContainerDtoContainerTypeEnum
  transitionMode: TransitionMode
  notification?: NotificationDto
  transitionDateTime?: string
  onTransitionSave?: (transition: TransitionDto) => void
  onCancel?: () => void
}

const ConardTransitionForm: FC<ConardTransitionFormProps> = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const auth = useConardAuth()
  const configContext = useContext(ConfigContext)
  const mrnRef = createRef<HTMLDivElement>()

  const { transitionMode, onTransitionSave, onCancel, containerType, notification } = props

  const transition = useSelector<RootState, TransitionDto>((state) => state.transition.transition)
  const transitionFormDisabled = useSelector<RootState, boolean>((state) => state.transition.transitionFormDisabled)
  const transitionPositionDisabled = useSelector<RootState, boolean>(
    (state) => state.transition.transitionPositionDisabled
  )
  const transitionSaveDisabled = useSelector<RootState, boolean>((state) => state.transition.transitionSaveDisabled)
  const comingFromDamageLog = useSelector<RootState, boolean>((state) => state.transition.comingFromDamageLog)
  const trainType = useSelector<RootState, TrainDtoTrainTypeEnum | undefined>((state) => state.train.train.trainType)
  const signetPhoto = useSelector<RootState, SignetPhotoState>((state) => state.signetPhoto)
  const dispatch = useAppDispatch()

  const [parentCompanies, setParentCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [companies, setCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [shipowners, setShipowners] = useState<ShipownerDto[] | undefined>(undefined)
  const [allShipowners, setAllShipowners] = useState<ShipownerDto[] | undefined>(undefined)
  const [allCompanies, setAllCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [mscShipownerId, setMscShipownerId] = useState<number | undefined>(undefined)
  const [maerskShipownerId, setMaerskShipownerId] = useState<number | undefined>(undefined)
  const [latestCodeco, setLatestCodeco] = useState<ProcessedFileDto | undefined>(undefined)
  const [temporaryCustomers, setTemporaryCustomers] = useState<CompanyDto[] | undefined>(undefined)

  const [driver, setDriver] = useState<DriverShortDto>({ id: undefined, name: '' })
  const [carrier, setCarrier] = useState<CarrierShortDto>({ id: undefined, name: '' })
  const [frontLicensePlate, setFrontLicensePlate] = useState<LicensePlateDto>({ id: undefined, licensePlate: '' })
  const [rearLicensePlate, setRearLicensePlate] = useState<LicensePlateDto>({ id: undefined, licensePlate: '' })

  const [nhmCodes, setNhmCodes] = useState<NhmCodeDto[]>([])
  const [additionalServices, setAdditionalServices] = useState<ExtraDto[]>([])
  const [iluCodes, setIluCodes] = useState<string[]>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [timerID, setTimerID] = useState<any>()
  const [mrnTags, setMrnTags] = useState<string[]>([])
  const [adrRidCodes, setAdrRidCodes] = useState<AdrRidCodeDto[]>([])
  const [showSignetPhotographer, setShowSignetPhotographer] = useState(false)
  const [showSignetPhotoDetail, setShowSignetPhotoDetail] = useState(false)
  const [loadingSignetPhoto, setLoadingSignetPhoto] = useState(true)
  const [isLockdown, setIsLockdown] = useState(false)
  const parkingDisabled = true

  const [showForm, setShowForm] = useState(true)
  const [showPhotographer, setShowPhotographer] = useState(false)
  const [store, setStore] = useState(false)
  const [takeout, setTakeout] = useState(false)
  const [showCodecoDialog, setShowCodecoDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showWrongIluDialog, setShowWrongIluDialog] = useState(false)
  const [showNhmSelection, setShowNhmSelection] = useState(false)
  const [showAdditionalServicesSelection, setShowAdditionalServicesSelection] = useState(false)
  const [showZeroDamageEntryDialog, setShowZeroDamageEntryDialog] = useState(false)
  const normalAndRoadDispatcher = new Set([UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad])

  const containsNewDamageLogEntries = !!(transition.damageLogEntries && transition.damageLogEntries.length > 0)
  const [containsAnyDamageLogEntries, setContainsAnyDamageLogEntries] = useState<boolean>(containsNewDamageLogEntries)
  const [importExportValue, setImportExportValue] = useState<TransitionDtoImportExportEnum>(
    transition.importExport ?? TransitionDtoImportExportEnum.Import
  )

  const gateOutMosnov = configContext.entryAndParking && transitionMode === TransitionMode.GATE_OUT
  const gateInMosnov = configContext.entryAndParking && transitionMode === TransitionMode.GATE_IN
  const gateOutBrno = !configContext.entryAndParking && transitionMode === TransitionMode.GATE_OUT
  const gateInBrno = !configContext.entryAndParking && transitionMode === TransitionMode.GATE_IN

  const isPlannedTransitionNotification =
    transitionMode === TransitionMode.NOTIFICATION &&
    transition?.containerState === TransitionDtoContainerStateEnum.PlannedGateIn

  const { control, handleSubmit, errors, getValues, trigger } = useForm<TransitionDto>({
    defaultValues: {
      ...transition,
    },
    resolver: yupResolver(TransitionFormValidationScheme(transitionMode, containsAnyDamageLogEntries, mscShipownerId)),
    reValidateMode: 'onBlur',
  })

  const isHeavyTestedMandatory = () => {
    return configContext.heavyTested && !transition.repleted
  }

  const heavyTestedMandatory = isHeavyTestedMandatory()

  const mrnScanKeys = ['Control', 'Shift', 'L']
  useKeyboardShortcut(
    mrnScanKeys,
    () => {
      if (mrnRef.current) {
        mrnRef.current!.focus()
      }
    },
    {
      overrideSystem: false,
      ignoreInputFields: false,
      repeatOnHold: false,
    }
  )

  const onChooseDriver = (autocompleteDto: AutocompleteDto) => {
    setDriver(autocompleteDto as DriverShortDto)
    driverService.findById(autocompleteDto.id ?? -1).then((response) => {
      setCarrier({
        id: response.data.carrierShort?.id,
        name: response.data.carrierShort?.name ?? '',
      })
      setFrontLicensePlate({
        id: response.data.frontLicensePlate?.id,
        licensePlate: response.data.frontLicensePlate?.licensePlate ?? '',
      })
      setRearLicensePlate({
        id: response.data.rearLicensePlate?.id,
        licensePlate: response.data.rearLicensePlate?.licensePlate ?? '',
      })
    })
  }

  const changeDriver = (autocompleteDto: AutocompleteDto) => {
    setDriver(autocompleteDto as DriverShortDto)
  }

  const changeCarrier = (autocompleteDto: AutocompleteDto) => {
    setCarrier(autocompleteDto as CarrierShortDto)
  }

  const changeFrontLicensePlate = (autocompleteDto: AutocompleteDto) => {
    setFrontLicensePlate({
      id: autocompleteDto.id,
      licensePlate: autocompleteDto.name ?? '',
    })
  }

  const changeRearLicensePlate = (autocompleteDto: AutocompleteDto) => {
    setRearLicensePlate({
      id: autocompleteDto.id,
      licensePlate: autocompleteDto.name ?? '',
    })
  }

  useEffect(() => {
    control.setValue('driverShort', driver)
    dispatch(setTransition({ ...transition, driverShort: driver }))
  }, [driver, control]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    control.setValue('carrierShort', carrier)
    dispatch(setTransition({ ...transition, carrierShort: carrier }))
  }, [carrier, control]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    control.setValue('frontLicensePlate', frontLicensePlate)
    dispatch(setTransition({ ...transition, frontLicensePlate: frontLicensePlate }))
  }, [frontLicensePlate, control]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    control.setValue('rearLicensePlate', rearLicensePlate)
    dispatch(setTransition({ ...transition, rearLicensePlate: rearLicensePlate }))
  }, [rearLicensePlate, control]) // eslint-disable-line react-hooks/exhaustive-deps

  const transitionDateTime = useWatch<string>({
    control,
    name: 'transitionDateTime',
  })
  useEffect(() => {
    if (
      configContext.storageAndDetentionDate &&
      transition.direction !== TransitionDtoDirectionEnum.Out &&
      !!transitionDateTime
    ) {
      const transitionDatetime = new Date(transitionDateTime)
      control.setValue('storageEndDate', addDays(transitionDatetime, 30))
    }
  }, [configContext.storageAndDetentionDate, transition.direction, transitionDateTime, control])

  const getParentCompanies = () => {
    companyService.findAllParentCompanies().then((response) => {
      setParentCompanies(response.data.content)
    })
  }

  const getAllCompanies = () => {
    companyService.findAll(0, 100, '', '').then((response) => {
      setCompanies(response.data.content)
      setAllCompanies(response.data.content)
      setTemporaryCustomers(response.data.content)
    })

    shipownerService.findAll(undefined, undefined, undefined).then((response) => {
      setShipowners(response.data.content)
      setAllShipowners(response.data.content)
      setMscShipownerId(response.data.content?.find((shipowner) => shipowner.name === 'MSC')?.id)
      setMaerskShipownerId(response.data.content?.find((shipowner) => shipowner.name === 'MAERSK')?.id)
    })
  }

  const getAllNhmCodes = () => {
    NhmService.findAllCodes(0, 20000, undefined).then((response) => {
      setNhmCodes(response.data.content ?? [])
    })
  }

  const getAllAdditionalServices = () => {
    transitionExtraService.findAll(0, 100, '', '').then((response) => {
      setAdditionalServices(response.data.content ?? [])
    })
  }

  const getAllAdrRidCodes = () => {
    RidService.findAllCodes(0, 4000, undefined).then((response) => {
      setAdrRidCodes(response.data.content ?? [])
    })
  }

  const getLatestCodeco = () => {
    if (transition.id) {
      ProcessedFileService.findLatestCodeco(transition.id)
        .then((response) => {
          setLatestCodeco(response.data)
        })
        .catch()
    }
  }

  const onChangeShipowner = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const companyId = shipowners?.find((shipowner) => shipowner.id === parseInt(event.target.value))?.companyId ?? -1
    const parentCompanyId = allCompanies?.find((company) => company.id === companyId)?.parentCompanyId ?? -1
    dispatch(
      setTransition({
        ...transition,
        container: {
          ...transition?.container,
          shipowner: {
            id: isNaN(parseInt(event.target.value)) ? undefined : parseInt(event.target.value),
            name: '',
          },
          company: {
            id: companyId,
            name: '',
            companyIdentificationNumber: '',
            companyTaxNumber: '',
            parentCompanyId: parentCompanyId,
          },
        },
      })
    )
    control.setValue('container.company.id', companyId !== -1 ? companyId : 'NOT-SELECTED')
    control.setValue('container.company.parentCompanyId', parentCompanyId !== -1 ? parentCompanyId : 'NOT-SELECTED')
    if (isNaN(parseInt(event.target.value))) {
      setShipowners(allShipowners)
      setCompanies(allCompanies)
    }
  }

  const onChangeCompany = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const parentCompanyId =
      companies?.find((company) => company.id === parseInt(event.target.value))?.parentCompanyId ?? -1
    dispatch(
      setTransition({
        ...transition,
        container: {
          ...transition?.container,
          shipowner: {
            id: undefined,
            name: '',
          },
          company: {
            id: isNaN(parseInt(event.target.value)) ? -1 : parseInt(event.target.value),
            parentCompanyId: parentCompanyId,
            name: '',
            companyIdentificationNumber: '',
            companyTaxNumber: '',
          },
        },
      })
    )
    control.setValue('container.shipowner.id', 'NOT-SELECTED')
    control.setValue('container.company.parentCompanyId', parentCompanyId !== -1 ? parentCompanyId : 'NOT-SELECTED')
    if (isNaN(parseInt(event.target.value))) {
      setShipowners(allShipowners)
      setCompanies(allCompanies)
    } else {
      setShipowners(allShipowners?.filter((shipowner) => shipowner.companyId === parseInt(event.target.value)))
    }
  }

  const onChangeTemporaryCustomer = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(
      setTransition({
        ...transition,
        container: {
          ...transition?.container,
        },
        temporaryCustomer: {
          id: isNaN(parseInt(event.target.value)) ? -1 : parseInt(event.target.value),
          name: '',
          companyIdentificationNumber: '',
          companyTaxNumber: '',
        },
      })
    )
  }

  const onChangeParentCompany = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(
      setTransition({
        ...transition,
        container: {
          ...transition?.container,
          shipowner: {
            id: -1,
            name: '',
          },
          company: {
            id: -1,
            parentCompanyId: isNaN(parseInt(event.target.value)) ? -1 : parseInt(event.target.value),
            name: '',
            companyIdentificationNumber: '',
            companyTaxNumber: '',
          },
        },
      })
    )
    control.setValue('container.company.id', 'NOT-SELECTED')
    control.setValue('container.shipowner.id', 'NOT-SELECTED')
    if (isNaN(parseInt(event.target.value))) {
      setCompanies(allCompanies)
      setShipowners(allShipowners)
    } else {
      const filteredCompanies = allCompanies?.filter(
        (company) => company.parentCompanyId === parseInt(event.target.value)
      )
      setCompanies(filteredCompanies)
      setShipowners(
        allShipowners?.filter((shipowner) =>
          filteredCompanies?.find((filteredCompany) => filteredCompany.id === shipowner.companyId)
        )
      )
    }
  }

  const onChangeShippingContainerType = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const shippingContainerType = event.target.value as ContainerDtoShippingContainerTypeEnum

    control.setValue('container.teu', Teu[shippingContainerType as keyof typeof Teu])

    dispatch(
      setTransition({
        ...transition,
        container: {
          ...transition?.container,
          teu: Teu[shippingContainerType as keyof typeof Teu],
          shippingContainerType: shippingContainerType,
        },
      })
    )
  }

  const onChangeSemitrailerType = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const semitrailerType = event.target.value as ContainerDtoSemitrailerTypeEnum

    control.setValue('container.teu', Teu[semitrailerType as keyof typeof Teu])
    control.setValue('container.semitrailerType', semitrailerType)

    const newContainer = {
      ...transition?.container,
      teu: Teu[semitrailerType as keyof typeof Teu],
      semitrailerType: semitrailerType,
    }
    onChangeTarraWeight(TarraWeight[semitrailerType as keyof typeof TarraWeight] + '', newContainer)
  }

  const onChangeQualityType = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    control.setValue('qualityType', event.target.value as TransitionDtoQualityTypeEnum)
    dispatch(
      setTransition({
        ...transition,
        qualityType: event.target.value as TransitionDtoQualityTypeEnum,
      })
    )
  }

  const onChangeSectorLetter = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    control.setValue('sectorLetter', event.target.value)
    dispatch(
      setTransition({
        ...transition,
        sectorLetter: event.target.value,
      })
    )
  }

  const onChangeReceiptMethod = (event: boolean) => {
    const newReceiptMethod = event ? TransitionDtoReceiptMethodEnum.Train : TransitionDtoReceiptMethodEnum.Road
    control.setValue('receiptMethod', newReceiptMethod)
    setImportExport(
      transition.direction ?? TransitionDtoDirectionEnum.In,
      transitionMode === TransitionMode.TRAIN ? TransitionDtoReceiptMethodEnum.Train : newReceiptMethod,
      transition.repleted!,
      transition.container
    ).then((response) => {
      setImportExportValue(response)
    })
    dispatch(
      setTransition({
        ...transition,
        receiptMethod: newReceiptMethod,
      })
    )
  }

  const onChangeRepleted = (event: boolean) => {
    control.setValue('repleted', event)
    control.setValue('hasSignet', event)
    control.setValue('netWeight', 0)
    control.setValue(
      'qualityType',
      transition.container.containerType !== ContainerDtoContainerTypeEnum.Semitrailer && event
        ? TransitionDtoQualityTypeEnum.Standard
        : 'NOT-SELECTED'
    )

    if (configContext.heavyTested) {
      control.setValue('container.heavyTested', true)
    }

    setImportExport(
      transition.direction ?? TransitionDtoDirectionEnum.In,
      transitionMode === TransitionMode.TRAIN ? TransitionDtoReceiptMethodEnum.Train : transition.receiptMethod!,
      event,
      transition.container
    ).then((response) => {
      setImportExportValue(response)
    })
    dispatch(
      setTransition({
        ...transition,
        container: {
          ...transition.container,
          heavyTested:
            configContext.heavyTested &&
            transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer,
        },
        repleted: event,
        hasSignet: event,
        netWeight: 0,
        qualityType:
          transition.container.containerType !== ContainerDtoContainerTypeEnum.Semitrailer && event
            ? TransitionDtoQualityTypeEnum.Standard
            : undefined,
      })
    )
  }

  const onChangeImportExport = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setImportExportValue(event.target.value as TransitionDtoImportExportEnum)
  }

  const onChangeAdrRidCodes = (event: React.ChangeEvent<{}>, value: AdrRidCodeDto[]) => {
    control.setValue('adrRidCodes', value)
    dispatch(
      setTransition({
        ...transition,
        adrRidCodes: value,
      })
    )
  }

  const onChangeNhmCodes = (event: React.ChangeEvent<{}>, value: NhmCodeDto[]) => {
    control.setValue('nhmCodes', value)
    dispatch(
      setTransition({
        ...transition,
        nhmCodes: value,
      })
    )
    setShowNhmSelection(false)
  }

  const onChangeAdditionalServices = (event: React.ChangeEvent<{}>, value: ExtraDto[]) => {
    control.setValue('transitionExtrasDto', value)
    dispatch(
      setTransition({
        ...transition,
        extras: value,
      })
    )
    setShowAdditionalServicesSelection(false)
  }

  const handleNhmSelection = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value !== '') {
      setShowNhmSelection(true)
    } else {
      setShowNhmSelection(false)
    }
  }

  const handleAdditionalServicesSelection = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value !== '') {
      setShowAdditionalServicesSelection(true)
    } else {
      setShowAdditionalServicesSelection(false)
    }
  }

  const onChangeMrn = (event: React.ChangeEvent<{}>, values: string[]) => {
    const mrns: TransitionMrnDto[] = []
    values.map((value) =>
      mrns.push({
        value: value as string,
      })
    )
    setMrnTags(values)
    control.setValue('transitionMrns', mrns)
    dispatch(
      setTransition({
        ...transition,
        transitionMrns: mrns,
      })
    )
  }

  useEffect(() => {
    if (transitionMode === TransitionMode.GATE_OUT && !comingFromDamageLog) {
      control.setValue('wagonNumber', '')
      setDriver(transition.driverShort ?? { id: undefined, name: '' })
      setCarrier(transition.carrierShort ?? { id: undefined, name: '' })
      setFrontLicensePlate(transition.frontLicensePlate ?? { id: undefined, licensePlate: '' })
      setRearLicensePlate(transition.rearLicensePlate ?? { id: undefined, licensePlate: '' })
      dispatch(
        setTransition({
          ...transition,
          driverShort: transition.driverShort ?? { id: undefined, name: '' },
          carrierShort: transition.carrierShort ?? { id: undefined, name: '' },
          frontLicensePlate: transition.frontLicensePlate ?? { id: undefined, licensePlate: '' },
          rearLicensePlate: transition.rearLicensePlate ?? { id: undefined, licensePlate: '' },
        })
      )
    } else {
      setDriver(transition.driverShort ?? { id: undefined, name: '' })
      setCarrier(transition.carrierShort ?? { id: undefined, name: '' })
      setFrontLicensePlate(transition.frontLicensePlate ?? { id: undefined, licensePlate: '' })
      setRearLicensePlate(transition.rearLicensePlate ?? { id: undefined, licensePlate: '' })
    }
  }, [transition.receiptMethod]) // eslint-disable-line react-hooks/exhaustive-deps

  const setSwitches = (transition: TransitionDto) => {
    control.setValue('hasSignet', transition.hasSignet)
    control.setValue('damaged', transition.damaged)
    control.setValue('weighted', transition.weighted)
    control.setValue('lockdown', transition.lockdown)
    control.setValue('adrRid', transition.adrRid)
    control.setValue('repleted', transition.repleted)
    control.setValue(
      'receiptMethod',
      transitionMode === TransitionMode.TRAIN
        ? TransitionDtoReceiptMethodEnum.Train
        : transition.receiptMethod === TransitionDtoReceiptMethodEnum.Road || !transition.receiptMethod
        ? TransitionDtoReceiptMethodEnum.Road
        : TransitionDtoReceiptMethodEnum.Train
    )
    control.setValue(
      'container.heavyTested',
      transition.container.heavyTested ||
        !!transition.container.heavyTestedValue ||
        (configContext.heavyTested && !transition.repleted)
    )
    control.setValue('hasTemporaryCustomer', !!transition.temporaryCustomer)
    control.setValue('container.innerWidth', transition.container.innerWidth)
    control.setValue('container.lashingRings', transition.container.lashingRings)
    control.setValue('container.woodenFloor', transition.container.woodenFloor)
    control.setValue('container.flexiTanks', transition.container.flexiTanks)
  }

  const onChangeIluCode = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(addIluCode(event.target.value))
    control.setValue('container.iluCode', event.target.value)

    clearTimeout(timerID)
    setTimerID(
      setTimeout(() => {
        gateInService
          .iluCodesForAutocomplete(event.target.value, transition.container.containerType)
          .then((response) => {
            setIluCodes(response.data)
          })
      }, 1000)
    )
  }

  const onChangeAutocompleteIluCode = (event: ChangeEvent<{}>, value: string) => {
    fillByIluCode(value)
  }

  const clickIluCode = (event: React.MouseEvent<HTMLDivElement>, option: string) => {
    fillByIluCode(option)
  }

  const fillByIluCode = (value: string) => {
    if (value !== null && transition?.receiptMethod === TransitionDtoReceiptMethodEnum.Road) {
      const searchDto = {
        container: {
          iluCode: value,
        },
        containerState: TransitionSearchDtoContainerStateEnum.FinishedGateOut,
        receiptMethod: TransitionSearchDtoReceiptMethodEnum.Road,
      }
      transitionService
        .search(0, 1, ['id,desc'], searchDto)
        .then((response) => {
          const lastTransition = response.data.content?.values().next().value
          if (!!lastTransition) {
            let teu = 1.0

            if (!!lastTransition?.container.shippingContainerType) {
              teu = Teu[lastTransition?.container.shippingContainerType as keyof typeof Teu]
            } else if (!!lastTransition?.container.semitrailerType) {
              teu = Teu[lastTransition?.container.semitrailerType as keyof typeof Teu]
            }

            dispatch(
              setTransition({
                ...transition,
                container: {
                  ...transition.container,
                  id: lastTransition.container.id,
                  iluCode: value as string,
                  shipowner: lastTransition?.container?.shipowner,
                  company: lastTransition?.container?.company,
                  semitrailerLicencePlate:
                    lastTransition?.container?.semitrailerLicencePlate ?? notification?.licensePlateRear,
                  tarraWeight: lastTransition?.container.tarraWeight,
                  heavyTested: lastTransition?.container.heavyTested || !!lastTransition.container.heavyTestedValue,
                  heavyTestedValue: lastTransition?.container.heavyTestedValue,
                  innerWidth: lastTransition?.container.innerWidth,
                  lashingRings: lastTransition?.container.lashingRings,
                  woodenFloor: lastTransition?.container.woodenFloor,
                  flexiTanks: lastTransition?.container.flexiTanks,
                  shippingContainerType: lastTransition?.container.shippingContainerType,
                  semitrailerType: lastTransition?.container.semitrailerType,
                  teu: teu,
                  iluPhoto: lastTransition.container.iluPhoto,
                },
                netWeight: 0,
              })
            )
            control.setValue('container.iluCode', value as string)
            control.setValue('container.shipowner', lastTransition?.container?.shipowner)
            control.setValue('container.company', lastTransition?.container?.company)
            control.setValue(
              'container.semitrailerLicencePlate',
              notification?.licensePlateRear ?? lastTransition?.container?.semitrailerLicencePlate
            )
            control.setValue('container.tarraWeight', lastTransition.container.tarraWeight ?? '')
            control.setValue('container.semitrailerType', lastTransition.container.semitrailerType)
            control.setValue('container.heavyTestedValue', lastTransition?.container.heavyTestedValue)
            control.setValue(
              'container.heavyTested',
              lastTransition?.container.heavyTested || lastTransition.container.heavyTestedValue
            )
            control.setValue('container.innerWidth', lastTransition?.container.innerWidth)
            control.setValue('container.lashingRings', lastTransition?.container.lashingRings)
            control.setValue('container.woodenFloor', lastTransition?.container.woodenFloor)
            control.setValue('container.flexiTanks', lastTransition?.container.flexiTanks)
            control.setValue('container.shippingContainerType', lastTransition?.container.shippingContainerType)
            control.setValue('container.semitrailerType', lastTransition?.container.semitrailerType)
            control.setValue('container.teu', teu)
            control.setValue('grossWeight', lastTransition?.container.tarraWeight)
            control.setValue('netWeight', 0)

            if (!notification) {
              setDriver(lastTransition.driverShort ?? { id: undefined, name: '' })
              setCarrier(lastTransition.carrierShort ?? { id: undefined, name: '' })
              setFrontLicensePlate(lastTransition.frontLicensePlate ?? { id: undefined, licensePlate: '' })
              setRearLicensePlate(lastTransition.rearLicensePlate ?? { id: undefined, licensePlate: '' })
            }
          }
        })
        .catch()
    }
  }

  const onNewDamageLogEntryRequested = () => {
    dispatch(setComingFromDamageLog(true))

    dispatch(
      setTransition({
        ...transition,
        ...getValues(),
        damaged: true,
        container: {
          ...getValues().container,
          id: transition.container.id,
          iluPhoto: transition.container.iluPhoto,
          heavyTested: transition.container.heavyTested,
          innerWidth: transition.container.innerWidth,
          lashingRings: transition.container.lashingRings,
          woodenFloor: transition.container.woodenFloor,
          flexiTanks: transition.container.flexiTanks,
        },
      })
    )

    if (!transition.container.iluPhoto) {
      setShowPhotographer(true)
    } else {
      history.push('/damage-log/new', { containerType: transition.container.containerType, startTab: 'damageLogEntry' })
    }

    setShowForm(false)
  }

  const onOldDamageLogEntry = () => {
    if (!transition.container.iluPhoto) {
      setShowZeroDamageEntryDialog(true)
    } else {
      dispatch(setComingFromDamageLog(true))

      dispatch(
        setTransition({
          ...transition,
          ...getValues(),
          damaged: true,
          container: {
            ...getValues().container,
            id: transition.container.id,
            iluPhoto: transition.container.iluPhoto,
            heavyTested: transition.container.heavyTested,
            innerWidth: transition.container.innerWidth,
            lashingRings: transition.container.lashingRings,
            woodenFloor: transition.container.woodenFloor,
            flexiTanks: transition.container.flexiTanks,
          },
        })
      )
      history.push('/damage-log/new', {
        containerType: transition.container.containerType,
        startTab: 'transitionDamages',
      })
      setShowForm(false)
    }
  }

  const onIluPhotoAccepted = (photo: string) => {
    setShowPhotographer(false)

    dispatch(
      setTransition({
        ...transition,
        container: {
          ...transition.container,
          iluPhoto: photo,
        },
      })
    )

    setShowForm(false)
    history.push('/damage-log/new', { containerType: containerType, startTab: 'damageLogEntry' })
  }

  const onChangeHasSignet = (event: boolean) => {
    control.setValue('hasSignet', event)
    dispatch(setTransition({ ...transition, hasSignet: event }))
  }

  const onChangeHasTemporaryCustomer = (event: boolean) => {
    control.setValue('hasTemporaryCustomer', event)
  }

  const onDelete = (id: number) => {
    transitionService.delete(id).finally(() => history.goBack())
  }

  const onFinishGo = () => {
    gateOutService.finishGateOut(transition.id).then((response) => {
      if (notification) {
        notificationService.update(notification.id ?? -1, {
          ...notification,
          closedAt: new Date().toISOString(),
        })
      }
      setShowForm(false)
      dispatch(setTransition(transitionInitialState.transition))
      history.replace('/success/transition/' + response.data.id + '/gate-out')
    })
  }

  const onChangeMaxGross = (value: string) => {
    let newMaxGross: number | undefined = Number(value)
    if (!isNaN(newMaxGross)) {
      if (newMaxGross < 0) {
        newMaxGross = 0
      }
      if (value.trim() === '') {
        newMaxGross = undefined
      }

      control.setValue('container.maxGross', newMaxGross)
      dispatch(setTransition({ ...transition, container: { ...transition.container, maxGross: newMaxGross } }))
    }
  }

  const onChangeNetWeight = (value: string) => {
    let newWeight: number | undefined = Number(value)
    if (!isNaN(newWeight)) {
      if (newWeight < 0) {
        newWeight = 0
      }

      if (value.trim() === '') {
        newWeight = undefined
      }

      control.setValue('netWeight', newWeight)
      control.setValue('grossWeight', (newWeight ?? 0) + (transition.container.tarraWeight ?? 0))

      dispatch(
        setTransition({
          ...transition,
          netWeight: newWeight,
        })
      )
    }
  }

  const onChangeTarraWeight = (value: string, newContainer?: ContainerDto) => {
    let newWeight: number | undefined = Number(value)
    if (!isNaN(newWeight)) {
      if (newWeight < 0) {
        newWeight = 0
      }

      if (value.trim() === '') {
        newWeight = undefined
      }

      control.setValue('container.tarraWeight', newWeight)
      control.setValue('grossWeight', (newWeight ?? 0) + (transition.netWeight ?? 0))

      dispatch(
        setTransition({
          ...transition,
          container: {
            ...transition.container,
            ...newContainer,
            tarraWeight: newWeight,
          },
        })
      )
    }
  }

  useEffect(() => {
    control.setValue('signetValue', transition?.signetValue)
  }, [transition.signetValue]) // eslint-disable-line react-hooks/exhaustive-deps

  const changeSwitch = (event: boolean, field: string) => {
    control.setValue(field, event)
    dispatch(setTransition({ ...transition, [field]: event }))
  }

  const handleLockdown = (lockdown: boolean) => {
    if (lockdown !== transition.lockdown) {
      dispatch(setTransition({ ...transition, lockdown: lockdown }))
      control.setValue('lockdown', lockdown)
    }
    setIsLockdown(lockdown)
  }

  const changeCheckbox = (field: string, checked: boolean) => {
    dispatch(setTransition({ ...transition, container: { ...transition.container, [field]: checked } }))
  }

  const handleDisabled = () => {
    dispatch(setTransitionFormDisabled(false))
    handlePositionDisabled()
  }

  const handlePositionDisabled = () => {
    if (
      transition.containerState === TransitionDtoContainerStateEnum.FinishedGateIn ||
      transition.containerState === TransitionDtoContainerStateEnum.ProcessingGateOut
    ) {
      dispatch(setTransitionPositionDisabled(false))
    }
    dispatch(setTransitionSaveDisabled(false))
  }

  const handleIncrement = () => {
    control.setValue('manipulation', (transition?.manipulation ?? 0) + 1)
    dispatch(setTransition({ ...transition, manipulation: (transition?.manipulation ?? 0) + 1 }))
  }

  const handleDecrement = () => {
    control.setValue('manipulation', (transition?.manipulation ?? 0) - 1)
    dispatch(setTransition({ ...transition, manipulation: (transition?.manipulation ?? 0) - 1 }))
  }

  const handleDownloadPdf = () => {
    gateInService
      .downloadDamageLog(transition?.id)
      .then((response) => {
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = format(new Date(), 'yyyyMMdd_') + transition?.container.iluCode || -1 + '.pdf'
        a.click()
      })
      .catch()
  }

  const handleProcess = async (continueProcess: boolean) => {
    if (!(await trigger())) {
      return
    }

    const gateIn = transition.containerState === TransitionDtoContainerStateEnum.PlannedGateIn
    const newContainerState = gateIn
      ? ContainerStateDtoStatusEnum.ProcessingGateIn
      : ContainerStateDtoStatusEnum.ProcessingGateOut

    if (gateIn && transitionMode === TransitionMode.NOTIFICATION && notification) {
      const values: TransitionDto = {
        ...transition,
        ...control.getValues(),
        direction: TransitionDtoDirectionEnum.In,
        transitionMrns: control.getValues('transitionMrns') ?? [],
        containerState: TransitionDtoContainerStateEnum.PlannedGateIn,
      }
      const response = await transitionService.update(
        transition.id || -1,
        cleanObject({
          ...values,
          container: {
            ...values.container,
            heavyTested: transition?.container.heavyTested,
            innerWidth: transition?.container.innerWidth,
            lashingRings: transition?.container.lashingRings,
            woodenFloor: transition?.container.woodenFloor,
            flexiTanks: transition?.container.flexiTanks,
          },
        })
      )

      if (response.status !== 200) {
        return
      }
    } else if (gateIn) {
      const response = await transitionService.update(
        transition.id || -1,
        cleanObject({
          ...transition,
          ...control.getValues(),
          direction: TransitionDtoDirectionEnum.In,
          transitionMrns: control.getValues('transitionMrns') ?? [],
          containerState: TransitionDtoContainerStateEnum.PlannedGateIn,
        })
      )

      if (response.status !== 200) {
        return
      }
    }

    const response = await transitionService.changeContainerState(transition.id!, newContainerState)

    if (response.status === 200) {
      if (notification) {
        notificationService.update(notification.id || -1, {
          ...notification,
          closedAt: new Date().toISOString(),
        })
      }
      if (gateIn) {
        setShowForm(false)
        dispatch(setTransition(transitionInitialState.transition))
        if (continueProcess) {
          history.replace('/gate-in/transition/store/' + response.data.id)
        } else {
          history.replace('/success/transition/' + response.data.id + '/gate-in')
        }
      } else {
        if (continueProcess) {
          gateOutService.finishGateOut(response.data.id)
        }
        setShowForm(false)
        dispatch(setTransition(transitionInitialState.transition))
        history.replace('/success/transition/' + transition.id)
      }
    }
  }

  const handleParkingLot = () => {
    asparkingSemaphoreControlService
      .asparkingSemaphoreControl(SemaphoreMode.RIGHT_DIRECTION, AsparkingCheckpoint.CHECKPOINT_2_IN)
      .then((response) => {
        if (response.status === 200) {
          if (notification) {
            notificationService.update(notification.id ?? -1, {
              ...notification,
              closedAt: new Date().toISOString(),
            })
          }
        }
      })
    history.goBack()
  }

  const checkIluAndSubmit = (values: TransitionDto) => {
    transitionService.isValidIluCode(values.container.iluCode ?? '').then((response) => {
      if (response.data) {
        return onSubmit(values)
      }

      setShowWrongIluDialog(true)
    })
  }

  const saveSignetPhoto = (transitionId?: number) => {
    if (signetPhoto.signetPhotoChanged && !!transitionId) {
      uploadedFileService.assignSignetPhoto(transitionId, { content: signetPhoto.signetPhoto })
    }
    dispatch(setInitialSignetPhoto(''))
  }

  const onSubmit = (values: TransitionDto) => {
    if (values.container) {
      values.container.containerType = transition.container.containerType
    }
    if (!transition.repleted) {
      values.transitionMrns = transitionInitialState.transition.transitionMrns

      values.hasSignet = false
    }

    const notShowAllReferencesConfig = !configContext.conardTransitionForm.showAllReferences

    values = {
      ...transition,
      ...values,
      id: transition.id,
      container: {
        ...transition.container,
        ...values.container,
        id: transition.container.id,
        iluPhoto: transition.container.iluPhoto,
        heavyTested: getValues().container?.heavyTested,
        heavyTestedValue: getValues().container?.heavyTestedValue,
        semitrailerLicencePlate: getValues().container?.semitrailerLicencePlate,
      },
      hasSignet: transition.repleted ? transition.hasSignet : false,
      temporaryCustomer: hasTemporaryCustomer ? transition.temporaryCustomer : undefined,
      damaged: containsNewDamageLogEntries || transition.damaged,
      damageLogEntries: transition.damageLogEntries,
      extras: transition.extras,
      receiptMethod: transition.receiptMethod,
      importExport: importExportValue,
      nhmCodes: transition.nhmCodes,
      repairShopStays: getValues().repairShopStays ?? [],
      bookingReference: notShowAllReferencesConfig && !getValues().repleted ? undefined : values.bookingReference,
      transitionAdditionalInfo:
        !!values.transitionAdditionalInfo?.medlogReference || !!transition.transitionAdditionalInfo?.id
          ? {
              ...transition.transitionAdditionalInfo,
              medlogReference:
                notShowAllReferencesConfig && getValues().repleted
                  ? undefined
                  : values.transitionAdditionalInfo?.medlogReference,
            }
          : undefined,
    }

    switch (transitionMode) {
      case TransitionMode.GATE_IN: {
        gateInService
          .createSimple(values)
          .then((response) => {
            saveSignetPhoto(response.data.id)
            setShowForm(false)
            dispatch(setTransition(transitionInitialState.transition))
            if (store) {
              if (configContext.vgm && values.weighted) {
                const queryParams = new URLSearchParams({
                  transitionId: response.data.id?.toString() ?? '',
                  iluCode: response.data.container.iluCode ?? '',
                  store: String(true),
                })
                history.replace(`/vgm/weigh?${queryParams}`)
              } else {
                history.replace(`/gate-in/transition/store/${response.data.id}`)
              }
            } else {
              history.replace(`/success/transition/${response.data.id}/gate-in`)
            }
          })
          .catch()
        break
      }
      case TransitionMode.GATE_OUT: {
        if (
          notification &&
          transition.id &&
          (notification.notificationType === NotificationDtoNotificationTypeEnum.GoCheckpoint2 ||
            notification.notificationType === NotificationDtoNotificationTypeEnum.GateOutCoparn)
        ) {
          transitionService
            .update(values.id || -1, cleanObject(values))
            .then(() => {
              gateOutService.finishGateOut(transition.id).then(() => {
                setTakeout(false)
              })

              setShowForm(false)
              dispatch(setTransition(transitionInitialState.transition))
              history.replace('/success/transition/' + transition.id)
            })
            .catch()
        } else {
          gateOutService
            .create(values)
            .then((response) => {
              saveSignetPhoto(response.data.id)
              if (takeout) {
                gateOutService.releaseContainer(response.data.id).then(() => {
                  setTakeout(false)
                })
              }
              setShowForm(false)
              dispatch(setTransition(transitionInitialState.transition))
              history.replace('/success/transition/' + response.data.id + '/gate-out')
            })
            .catch()
        }

        break
      }
      case TransitionMode.SEARCH: {
        values = {
          ...transition,
          ...values,
          container: {
            ...values.container,
            id: transition.container.id,
          },
        }
        transitionService
          .update(values.id || -1, cleanObject(values))
          .then((response) => {
            saveSignetPhoto(response.data.id)
            setShowForm(false)
            dispatch(setTransition(transitionInitialState.transition))
            history.replace('/success/transition/' + response.data.id + '/update')
          })
          .catch()
        break
      }
      case TransitionMode.NOTIFICATION: {
        gateInService
          .createSimple(values)
          .then((response) => {
            saveSignetPhoto(response.data.id)
            setShowForm(false)
            dispatch(setTransition(transitionInitialState.transition))
            if (store) {
              if (configContext.vgm && values.weighted) {
                const queryParams = new URLSearchParams({
                  transitionId: response.data.id?.toString() ?? '',
                  iluCode: response.data.container.iluCode ?? '',
                  store: String(true),
                })
                history.replace(`/vgm/weigh?${queryParams}`)
              } else {
                history.replace(`/gate-in/transition/store/${response.data.id}`)
              }
            } else {
              history.replace(`/success/transition/${response.data.id}/gate-in`, { notification })
            }
          })
          .catch()
        break
      }
      case TransitionMode.TRAIN: {
        if (onTransitionSave) {
          onTransitionSave(values)
        }
        break
      }
    }

    if (notification) {
      notificationService
        .update(notification.id || -1, {
          ...notification,
          closedAt: new Date().toISOString(),
        })
        .catch()

      if (notification?.id) {
        toast.dismiss(notification.id)
      }
    }
  }

  useEffect(() => {
    if (
      notification &&
      notification.iluCode &&
      (notification.notificationType === NotificationDtoNotificationTypeEnum.GiCheckpoint2 ||
        notification.notificationType === NotificationDtoNotificationTypeEnum.FailedGreenPass) &&
      transitionMode !== TransitionMode.GATE_OUT
    ) {
      fillByIluCode(notification!.iluCode!)
    }
  }, [notification]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(setTransitionFormDisabled(transitionInitialState.transitionFormDisabled))
      dispatch(setTransitionPositionDisabled(transitionInitialState.transitionPositionDisabled))
      dispatch(setTransitionSaveDisabled(transitionInitialState.transitionSaveDisabled))
      dispatch(setComingFromDamageLog(transitionInitialState.comingFromDamageLog))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getSignetPhoto = () => {
    if (!!transition.id && !comingFromDamageLog) {
      uploadedFileService.getSignetPhoto(transition.id).then((response) => {
        dispatch(setInitialSignetPhoto(response.data.content ?? ''))
        setLoadingSignetPhoto(false)
      })
    } else if (!comingFromDamageLog) {
      dispatch(setInitialSignetPhoto(''))
      setLoadingSignetPhoto(false)
    } else {
      setLoadingSignetPhoto(false)
    }
  }

  useEffect(() => {
    control.register('id')
    control.register('damageLogEntries')
    control.register('container.id')
    control.register('container.containerType')
    control.register('container.iluPhoto')
    control.register('container.teu')

    getParentCompanies()
    getAllCompanies()
    getAllNhmCodes()
    getAllAdrRidCodes()
    getLatestCodeco()
    getSignetPhoto()
    getAllAdditionalServices()

    control.setValue('container.iluCode', transition.container.iluCode ?? '')
    control.setValue('container.semitrailerLicencePlate', transition.container.semitrailerLicencePlate ?? '')
    control.setValue('container.shipowner', transition.container.shipowner ?? '')

    if (transition.container.company) {
      control.setValue('container.company.parentCompanyId', transition.container.company.parentCompanyId)
      control.setValue('container.company.id', transition.container.company.id)
    }

    control.setValue('temporaryCustomer.id', transition?.temporaryCustomer?.id ?? 'NOT-SELECTED')
    control.setValue('container.shippingContainerType', transition?.container?.shippingContainerType ?? 'NOT-SELECTED')
    control.setValue('container.semitrailerType', transition?.container?.semitrailerType ?? 'NOT-SELECTED')
    control.setValue('container.teu', transition?.container?.teu !== null ? transition?.container?.teu : 1.0)
    control.setValue('signetValue', transition?.signetValue)
    control.setValue('bookingReference', transition.bookingReference)
    control.setValue(
      'transitionAdditionalInfo.medlogReference',
      transition.transitionAdditionalInfo?.medlogReference ?? ''
    )
    control.setValue('qualityType', transition.qualityType ?? 'NOT-SELECTED')
    control.setValue('transitionDateTime', transition?.transitionDateTime ?? props.transitionDateTime ?? new Date())
    control.setValue('netWeight', transition?.netWeight)
    control.setValue('container.tarraWeight', transition?.container.tarraWeight)
    control.setValue('transitionExtrasDto', transition?.extras)
    control.setValue('note', transition?.note)
    control.setValue('transitionMrns', transition?.transitionMrns)
    control.setValue('manipulation', transition?.manipulation)
    control.setValue('container.heavyTestedValue', transition?.container.heavyTestedValue)
    control.setValue('repairShopStays', transition.repairShopStays ?? [])

    // this also sets the value to control and to redux
    setDriver(transition.driverShort ?? { id: undefined, name: '' })
    setCarrier(transition.carrierShort ?? { id: undefined, name: '' })
    setFrontLicensePlate(transition.frontLicensePlate ?? { id: undefined, licensePlate: '' })
    setRearLicensePlate(transition.rearLicensePlate ?? { id: undefined, licensePlate: '' })
    if (
      notification &&
      (notification.notificationType == NotificationDtoNotificationTypeEnum.GiCheckpoint2WithoutIluCode ||
        notification.notificationType == NotificationDtoNotificationTypeEnum.GoCheckpoint2 ||
        notification.notificationType == NotificationDtoNotificationTypeEnum.GiCheckpoint2 ||
        notification.notificationType == NotificationDtoNotificationTypeEnum.FailedGreenPass)
    ) {
      if (!!notification.licensePlateFront && !transition.frontLicensePlate) {
        setFrontLicensePlate({ id: undefined, licensePlate: notification.licensePlateFront })
      }

      if (!!notification.licensePlateRear) {
        if (
          containerType === ContainerDtoContainerTypeEnum.ShippingContainer &&
          transitionMode !== TransitionMode.GATE_OUT &&
          !transition.rearLicensePlate
        ) {
          setRearLicensePlate({ id: undefined, licensePlate: notification.licensePlateRear })
        }

        if (
          containerType === ContainerDtoContainerTypeEnum.Semitrailer &&
          transitionMode !== TransitionMode.GATE_OUT &&
          !transition.container.semitrailerLicencePlate
        ) {
          control.setValue('container.semitrailerLicencePlate', notification.licensePlateRear)
        }
      }
    }

    if (transitionMode !== TransitionMode.SEARCH) {
      setImportExport(
        transition.direction ?? TransitionDtoDirectionEnum.In,
        transitionMode === TransitionMode.TRAIN ? TransitionDtoReceiptMethodEnum.Train : transition.receiptMethod!,
        transition.repleted!,
        transition.container
      ).then((response) => {
        setImportExportValue(response)
      })
    }

    setSwitches(transition)
    setTransitionMrns()
    //
  }, [showForm, notification, transitionMode]) // eslint-disable-line react-hooks/exhaustive-deps

  const setTransitionMrns = () => {
    if (transition?.transitionMrns) {
      let mrns: string[] = []
      transition.transitionMrns.map((mrn) => {
        mrns = [...mrns, mrn.value]
      })
      setMrnTags(mrns)
    }
  }

  const handlePositionDecrement = (value: string) => {
    const currentValue: number = control.getValues(value)
    if (currentValue && currentValue > 1) {
      control.setValue(value, currentValue - 1)
      dispatch(setTransition({ ...transition, [value]: currentValue - 1 }))
    } else if (!currentValue) {
      control.setValue(value, 1)
      dispatch(setTransition({ ...transition, [value]: 1 }))
    }
  }

  const handlePositionIncrement = (value: string) => {
    const currentValue: number = control.getValues(value)
    const maxValue =
      value === 'sectorFloor'
        ? configContext.coordinates.maxSectorFloor
        : value === 'sectorRow'
        ? configContext.coordinates.maxSectorRow
        : configContext.coordinates.maxSectorColumn
    if (currentValue && currentValue < maxValue) {
      control.setValue(value, currentValue + 1)
      dispatch(setTransition({ ...transition, [value]: currentValue + 1 }))
    } else if (!currentValue) {
      control.setValue(value, 1)
      dispatch(setTransition({ ...transition, [value]: 1 }))
    }
  }

  const resendCodeco = () => {
    gateOutService
      .update(transition.id!)
      .then(() => {
        setShowCodecoDialog(true)
        processedFileService.findLatestCodeco(transition.id!).then((response) => {
          setLatestCodeco(response.data)
        })
      })
      .catch()
  }

  const acceptNewSignetPhoto = (photo: string) => {
    dispatch(changeSignetPhoto(photo))
    setShowSignetPhotographer(false)
  }

  const handleAddSignetPhoto = () => {
    if (!loadingSignetPhoto && !transitionFormDisabled) {
      setShowSignetPhotographer(true)
      setShowSignetPhotoDetail(false)
    }
  }

  const handleDeleteSignetPhoto = () => {
    setShowSignetPhotoDetail(false)
    dispatch(changeSignetPhoto(''))
  }

  const hasTemporaryCustomer = useWatch<boolean>({
    name: 'hasTemporaryCustomer',
    control: control,
  })

  useEffect(() => {
    if (configContext.showNhmCodes && containerType === ContainerDtoContainerTypeEnum.Semitrailer) {
      const defaultNhmCodes = transition.repleted
        ? [
            {
              id: 12597,
              code: '99430000',
              nameCz: 'Návěsy, ložené',
              noteCz: 'Návěsy, ložené',
              nameEn: 'Semi trailers, loaded',
              noteEn: 'Semi trailers, loaded',
            },
          ]
        : [
            {
              id: 12591,
              code: '99330000',
              nameCz: 'Návěsy, prázdné použité a před nebo po loženém běhu po železnici',
              noteCz: 'Návěsy, prázdné použité a před nebo po loženém běhu po železnici',
              nameEn: 'Semi trailers, empty, used, before or after a transport by rail with payload.',
              noteEn: 'Semi trailers, empty, used, before or after a transport by rail with payload.',
            },
          ]

      const backendNhmCodes = transition.nhmCodes || []

      const includesDefaultNhmCode = backendNhmCodes.some((nhmCode) =>
        defaultNhmCodes.find((defaultNhmCode) => defaultNhmCode.id === nhmCode.id)
      )

      const allNhmCodes = includesDefaultNhmCode ? backendNhmCodes : [...defaultNhmCodes, ...backendNhmCodes]

      control.setValue('nhmCodes', allNhmCodes)
    }
  }, [transition.repleted, transition.nhmCodes, configContext.showNhmCodes, containerType, control])

  const handleNewCarrier = (carrier: CarrierShortDto) => {
    setCarrier(carrier)
  }

  return (
    <>
      <form onSubmit={handleSubmit(checkIluAndSubmit)}>
        {showForm && (
          <>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              {transitionMode === TransitionMode.GATE_IN && !isPlannedTransitionNotification && (
                <Grid item xs={12}>
                  <Typography variant="h4" color="primary">
                    {containerType === ContainerDtoContainerTypeEnum.ShippingContainer
                      ? t('pages.gateIn.headers.newShippingContainer')
                      : t('pages.gateIn.headers.newSemitrailer')}
                  </Typography>
                </Grid>
              )}
              {transitionMode === TransitionMode.GATE_OUT && (
                <Grid item xs={12}>
                  <Typography variant="h4" color="primary">
                    {containerType === ContainerDtoContainerTypeEnum.ShippingContainer
                      ? t('pages.gateOut.headers.shippingContainer')
                      : t('pages.gateOut.headers.semitrailer')}
                  </Typography>
                </Grid>
              )}
              {(new Set([TransitionMode.SEARCH, TransitionMode.TRAIN]).has(transitionMode) ||
                isPlannedTransitionNotification) && (
                <Grid item lg={5} xs={12}>
                  <Typography variant="h4" color="primary">
                    {containerType === ContainerDtoContainerTypeEnum.ShippingContainer
                      ? t('pages.searchTransition.detail.shippingContainer')
                      : t('pages.searchTransition.detail.semitrailer')}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Grid item xl={9} lg={9} md={12} sm={12} xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  className={classes.container}
                  spacing={7}
                >
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    {!new Set([TransitionMode.GATE_IN, TransitionMode.TRAIN, TransitionMode.NOTIFICATION]).has(
                      transitionMode
                    ) && (
                      <ConardTextField
                        id="container.iluCode"
                        name="container.iluCode"
                        label={t('pages.gateIn.form.iluCode.label')}
                        control={control}
                        disabled={transitionFormDisabled}
                        error={!!errors.container?.iluCode}
                        helperText={errors.container?.iluCode ? errors.container?.iluCode?.message : ' '}
                        defaultValue={transition?.container.iluCode ?? ''}
                      />
                    )}
                    {!new Set([TransitionMode.GATE_OUT, TransitionMode.SEARCH]).has(transitionMode) && (
                      <Controller
                        id="container.iluCode"
                        name="container.iluCode"
                        defaultValue=""
                        control={control}
                        render={(autoProps) => (
                          <Autocomplete
                            {...autoProps}
                            clearOnBlur={false}
                            options={iluCodes}
                            noOptionsText={t('any.noOptions')}
                            getOptionLabel={(option) => option}
                            limitTags={4}
                            renderOption={(option) => (
                              <div className={classes.autocompleteOption} onClick={(e) => clickIluCode(e, option)}>
                                {option}
                              </div>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t('pages.gateIn.form.iluCode.label')}
                                variant="outlined"
                                onChange={(event) => onChangeIluCode(event)}
                                disabled={transitionFormDisabled}
                                error={!!errors.container?.iluCode}
                                helperText={errors.container?.iluCode ? errors.container?.iluCode?.message : ' '}
                                InputProps={{
                                  ...params.InputProps,
                                  className: classes.inputAutocomplete,
                                }}
                                className={classes.autocomplete}
                              />
                            )}
                            onChange={(event, data) => onChangeAutocompleteIluCode(event, data)}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <ConardSwitch
                      id="repleted"
                      name="repleted"
                      checked={transition?.repleted}
                      onChange={(event) => onChangeRepleted(event)}
                      control={control}
                      disabled={transitionFormDisabled}
                      checkedLabel={t('pages.gateIn.form.repleted.checked')}
                      uncheckedLabel={t('pages.gateIn.form.repleted.unchecked')}
                      helperText=" "
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    {transition.container.containerType === ContainerDtoContainerTypeEnum.Semitrailer && (
                      <ConardTextField
                        id="container.semitrailerLicencePlate"
                        name="container.semitrailerLicencePlate"
                        label={t('pages.gateIn.form.semitrailerLicencePlate.label')}
                        defaultValue={transition?.container?.semitrailerLicencePlate ?? ''}
                        control={control}
                        disabled={transitionFormDisabled}
                        error={!!errors.container?.semitrailerLicencePlate}
                        helperText={
                          errors.container?.semitrailerLicencePlate
                            ? errors.container?.semitrailerLicencePlate?.message
                            : ' '
                        }
                      />
                    )}
                    {transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer && (
                      <ConardSelect
                        id="container.shipowner.id"
                        name="container.shipowner.id"
                        label={t('pages.gateIn.form.shipowner.label')}
                        defaultValue="NOT-SELECTED"
                        value={
                          transition?.container?.shipowner?.id === undefined ||
                          transition?.container?.shipowner?.id === -1
                            ? 'NOT-SELECTED'
                            : transition?.container.shipowner.id
                        }
                        control={control}
                        disabled={transitionFormDisabled}
                        error={!!errors.container?.shipowner?.id}
                        helperText={errors.container?.shipowner?.id ? errors.container?.shipowner.id.message : ' '}
                        onChange={(event) => onChangeShipowner(event)}
                      >
                        <MenuItem value="NOT-SELECTED">{t('any.notSelected')}</MenuItem>
                        {shipowners?.map((shipowner) => (
                          <MenuItem key={shipowner.id} value={shipowner.id}>
                            {shipowner.name}
                          </MenuItem>
                        ))}
                      </ConardSelect>
                    )}
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <ConardSelect
                      id="container.company.parentCompanyId"
                      name="container.company.parentCompanyId"
                      label={t('pages.gateIn.form.parentCompany.label')}
                      defaultValue="NOT-SELECTED"
                      value={
                        transition?.container?.company?.parentCompanyId === undefined ||
                        transition?.container?.company?.parentCompanyId === -1
                          ? 'NOT-SELECTED'
                          : transition?.container?.company?.parentCompanyId
                      }
                      control={control}
                      disabled={transitionFormDisabled}
                      error={!!errors.container?.company?.parentCompanyId}
                      helperText={
                        errors.container?.company?.parentCompanyId
                          ? errors.container?.company?.parentCompanyId?.message
                          : ' '
                      }
                      onChange={(event) => onChangeParentCompany(event)}
                    >
                      <MenuItem value="NOT-SELECTED">{t('any.notSelected')}</MenuItem>
                      {parentCompanies?.map((parentCompany) => (
                        <MenuItem key={parentCompany.id} value={parentCompany.id}>
                          {parentCompany.name}
                        </MenuItem>
                      ))}
                    </ConardSelect>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <ConardSelect
                      id="container.company.id"
                      name="container.company.id"
                      label={t('pages.gateIn.form.company.label')}
                      defaultValue="NOT-SELECTED"
                      control={control}
                      disabled={transitionFormDisabled}
                      value={
                        transition?.container?.company?.id === undefined || transition?.container?.company?.id === -1
                          ? 'NOT-SELECTED'
                          : transition?.container?.company?.id
                      }
                      error={!!errors.container?.company?.id}
                      helperText={errors.container?.company?.id ? errors.container?.company?.id?.message : ' '}
                      onChange={(event) => onChangeCompany(event)}
                    >
                      <MenuItem value="NOT-SELECTED">{t('any.notSelected')}</MenuItem>
                      {companies?.map((company) => (
                        <MenuItem key={company.id} value={company.id}>
                          <div className={classes.companySelectItem}>
                            {company.name}
                            <div style={{ backgroundColor: company.color }} className={classes.colorDiv} />
                          </div>
                        </MenuItem>
                      ))}
                    </ConardSelect>
                  </Grid>

                  {configContext.temporaryCustomer && (
                    <>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <ConardSwitch
                          id="hasTemporaryCustomer"
                          name="hasTemporaryCustomer"
                          defaultChecked={false}
                          checked={hasTemporaryCustomer}
                          onChange={(event) => onChangeHasTemporaryCustomer(event)}
                          control={control}
                          disabled={transitionFormDisabled}
                          checkedLabel={t('pages.gateIn.form.hasTemporaryCustomer.checked')}
                          uncheckedLabel={t('pages.gateIn.form.hasTemporaryCustomer.unchecked')}
                          helperText=" "
                        />
                      </Grid>
                      {hasTemporaryCustomer && (
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <ConardSelect
                            id="temporaryCustomer.id"
                            name="temporaryCustomer.id"
                            label={t('pages.gateIn.form.temporaryCustomer.label')}
                            defaultValue="NOT-SELECTED"
                            control={control}
                            value={
                              transition?.temporaryCustomer?.id === undefined || transition.temporaryCustomer.id === -1
                                ? 'NOT-SELECTED'
                                : transition.temporaryCustomer.id
                            }
                            error={!!errors.temporaryCustomer?.id}
                            helperText={errors.temporaryCustomer?.id ? errors.temporaryCustomer?.id.message : ' '}
                            onChange={(event) => onChangeTemporaryCustomer(event)}
                            disabled={transitionFormDisabled}
                          >
                            <MenuItem value="NOT-SELECTED">{t('any.notSelected')}</MenuItem>
                            {temporaryCustomers?.map((company) => (
                              <MenuItem key={company.id} value={company.id}>
                                <div className={classes.companySelectItem}>
                                  {company.name}
                                  <div style={{ backgroundColor: company.color }} className={classes.colorDiv} />
                                </div>
                              </MenuItem>
                            ))}
                          </ConardSelect>
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    {transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer && (
                      <ConardSelect
                        id="container.shippingContainerType"
                        name="container.shippingContainerType"
                        label={t('pages.gateIn.form.shippingContainerType.label')}
                        defaultValue="NOT-SELECTED"
                        disabled={transitionFormDisabled}
                        value={transition?.container?.shippingContainerType ?? 'NOT-SELECTED'}
                        onChange={(event) => onChangeShippingContainerType(event)}
                        error={!!errors.container?.shippingContainerType}
                        helperText={
                          errors.container?.shippingContainerType
                            ? errors.container?.shippingContainerType.message
                            : ' '
                        }
                        control={control}
                      >
                        <MenuItem value="NOT-SELECTED">{t('any.notSelected')}</MenuItem>
                        {Object.values(ContainerDtoShippingContainerTypeEnum).map((shippingContainerType) => (
                          <MenuItem key={shippingContainerType} value={shippingContainerType}>
                            {cleanShippingContainerType(shippingContainerType)}
                          </MenuItem>
                        ))}
                      </ConardSelect>
                    )}
                    {transition.container.containerType === ContainerDtoContainerTypeEnum.Semitrailer && (
                      <ConardSelect
                        name="container.semitrailerType"
                        label={t('pages.gateIn.form.semitrailerType.label')}
                        defaultValue="NOT-SELECTED"
                        disabled={transitionFormDisabled}
                        value={transition?.container?.semitrailerType ?? 'NOT-SELECTED'}
                        onChange={(event) => {
                          onChangeSemitrailerType(event)
                        }}
                        error={!!errors.container?.semitrailerType}
                        helperText={errors.container?.semitrailerType ? errors.container?.semitrailerType.message : ' '}
                        control={control}
                      >
                        <MenuItem value="NOT-SELECTED">{t('any.notSelected')}</MenuItem>
                        {Object.values(ContainerDtoSemitrailerTypeEnum).map((semitrailerType) => (
                          <MenuItem key={semitrailerType} value={semitrailerType}>
                            {semitrailerType}
                          </MenuItem>
                        ))}
                      </ConardSelect>
                    )}
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <ConardTextField
                      id="container.teu"
                      name="container.teu"
                      control={control}
                      value={transition?.container?.teu ?? ''}
                      label={t('pages.gateIn.form.teu.label')}
                      disabled
                      helperText=" "
                    />
                  </Grid>
                  {transition.repleted && (
                    <>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <ConardSwitch
                          id="hasSignet"
                          name="hasSignet"
                          checked={transition?.hasSignet}
                          onChange={(event) => onChangeHasSignet(event)}
                          control={control}
                          disabled={transitionFormDisabled}
                          checkedLabel={t('pages.gateIn.form.hasSignet.checked')}
                          uncheckedLabel={t('pages.gateIn.form.hasSignet.unchecked')}
                          helperText=" "
                        />
                      </Grid>
                      {transition.hasSignet && (
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <ConardTextField
                            id="signetValue"
                            name="signetValue"
                            defaultValue=""
                            label={t('pages.gateIn.form.signetValue.label')}
                            control={control}
                            disabled={transitionFormDisabled}
                            error={!!errors.signetValue}
                            helperText={errors.signetValue ? errors.signetValue.message : ' '}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <ConardSwitch
                      id="damaged"
                      name="damaged"
                      checked={transition?.damaged || containsNewDamageLogEntries}
                      onChange={(event) => changeSwitch(event, 'damaged')}
                      control={control}
                      disabled={transitionFormDisabled || containsNewDamageLogEntries}
                      checkedLabel={t('pages.gateIn.form.damaged.checked')}
                      uncheckedLabel={t('pages.gateIn.form.damaged.unchecked')}
                      helperText=" "
                    />
                  </Grid>
                  {(configContext.conardTransitionForm.showAllReferences || !!transition.repleted) && (
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <ConardTextField
                        id="bookingReference"
                        name="bookingReference"
                        label={t('pages.gateIn.form.bookingReference.label')}
                        control={control}
                        disabled={transitionFormDisabled}
                        error={!!errors.bookingReference}
                        helperText={errors.bookingReference ? errors.bookingReference.message : ' '}
                      />
                    </Grid>
                  )}
                  {(configContext.conardTransitionForm.showAllReferences || !transition.repleted) && (
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <ConardTextField
                        id="transitionAdditionalInfo.medlogReference"
                        name="transitionAdditionalInfo.medlogReference"
                        label={
                          configContext.generalCarrierReference
                            ? t('pages.gateIn.form.carrierReference.label')
                            : t('pages.gateIn.form.medlogReference.label')
                        }
                        control={control}
                        disabled={transitionFormDisabled}
                        error={!!errors.transitionAdditionalInfo?.medlogReference}
                        helperText={errors.transitionAdditionalInfo?.medlogReference?.message || ' '}
                      />
                    </Grid>
                  )}
                  {transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer && (
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <ConardSelect
                        id="qualityType"
                        name="qualityType"
                        label={t('pages.gateIn.form.qualityType.label')}
                        defaultValue="NOT-SELECTED"
                        disabled={transitionFormDisabled}
                        value={transition?.qualityType ?? 'NOT-SELECTED'}
                        onChange={(event) => onChangeQualityType(event)}
                        error={!!errors.qualityType}
                        helperText={errors.qualityType ? errors.qualityType.message : ' '}
                        control={control}
                      >
                        <MenuItem value="NOT-SELECTED">{t('any.notSelected')}</MenuItem>
                        {Object.values(TransitionDtoQualityTypeEnum).map((qualityType) => (
                          <MenuItem key={qualityType} value={qualityType}>
                            {t(`enum.qualityType.${qualityType}`)}
                          </MenuItem>
                        ))}
                      </ConardSelect>
                    </Grid>
                  )}
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <ConardSwitch
                      id="adrRid"
                      name="adrRid"
                      checked={transition?.adrRid}
                      onChange={(event) => changeSwitch(event, 'adrRid')}
                      control={control}
                      disabled={transitionFormDisabled}
                      checkedLabel={t('pages.gateIn.form.adrRid.checked')}
                      uncheckedLabel={t('pages.gateIn.form.adrRid.unchecked')}
                      helperText=" "
                    />
                  </Grid>
                  {transition.adrRid && (
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Controller
                        id="adrRidCodes"
                        name="adrRidCodes"
                        defaultValue={adrRidCodes}
                        control={control}
                        render={() => (
                          <Autocomplete
                            multiple
                            filterSelectedOptions
                            limitTags={6}
                            options={adrRidCodes}
                            defaultValue={transition?.adrRidCodes}
                            noOptionsText={t('any.noOptions')}
                            getOptionLabel={(option) =>
                              `${option.unCode ?? ''} - ${option.nameCz ?? ''}${
                                option.packingGroup ? ', ' + option.packingGroup : ''
                              }`
                            }
                            disabled={transitionFormDisabled}
                            onChange={(event, data) => onChangeAdrRidCodes(event, data)}
                            renderTags={(tagValue: AdrRidCodeDto[], getTagProps) =>
                              tagValue.map((option, index) => (
                                <>
                                  <ConardTooltip
                                    title={`${option.unCode ?? ''} - ${option.nameCz ?? ''}${
                                      option.packingGroup ? ', ' + option.packingGroup : ''
                                    }`}
                                  >
                                    <Chip
                                      classes={{ label: classes.chipLabel }}
                                      key={option.unCode}
                                      label={`${option.unCode ?? ''} - ${option.nameCz ?? ''}${
                                        option.packingGroup ? ', ' + option.packingGroup : ''
                                      }`}
                                      {...getTagProps({ index })}
                                    />
                                  </ConardTooltip>
                                </>
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t('pages.gateIn.form.adrRid.label')}
                                variant="outlined"
                                disabled={transitionFormDisabled}
                                error={!!errors.adrRidCodes}
                                helperText={errors.adrRidCodes ? t('pages.gateIn.validations.rid') : ' '}
                                InputProps={{
                                  ...params.InputProps,
                                  className: classes.nhmInputAutocomplete,
                                }}
                                inputRef={mrnRef}
                                className={classes.autocomplete}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <ConardSwitch
                      id="agency"
                      name="agency"
                      checked={
                        transitionMode === TransitionMode.TRAIN
                          ? trainType === TrainDtoTrainTypeEnum.Partial
                          : transition?.agency
                      }
                      onChange={(event) => changeSwitch(event, 'agency')}
                      control={control}
                      disabled={transitionFormDisabled || transitionMode === TransitionMode.TRAIN}
                      checkedLabel={t('pages.gateIn.form.agency.checked')}
                      uncheckedLabel={t('pages.gateIn.form.agency.unchecked')}
                      helperText=" "
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <ConardSwitch
                      id="receiptMethod"
                      name="receiptMethod"
                      checked={
                        transitionMode === TransitionMode.TRAIN
                          ? true
                          : transition?.receiptMethod !== TransitionDtoReceiptMethodEnum.Road
                      }
                      control={control}
                      disabled={transitionMode === TransitionMode.TRAIN || transitionFormDisabled}
                      onChange={(event) => onChangeReceiptMethod(event)}
                      checkedLabel={t('pages.gateIn.form.receiptMethod.checked')}
                      uncheckedLabel={t('pages.gateIn.form.receiptMethod.unchecked')}
                      helperText=" "
                    />
                  </Grid>
                  {(transition.repleted ||
                    (configContext.showNhmCodes && containerType === ContainerDtoContainerTypeEnum.Semitrailer)) && (
                    <>
                      {transition.container.containerType === ContainerDtoContainerTypeEnum.Semitrailer &&
                        !configContext.showNhmCodes &&
                        ((configContext.conardTransitionForm.showAllReferences &&
                          !!transition.hasSignet !== !!transition.adrRid) ||
                          (!configContext.conardTransitionForm.showAllReferences &&
                            !!transition.hasSignet === !!transition.adrRid)) && (
                          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} />
                        )}
                      {transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer &&
                        ((configContext.conardTransitionForm.showAllReferences &&
                          !!transition.hasSignet === !!transition.adrRid) ||
                          (!configContext.conardTransitionForm.showAllReferences &&
                            !!transition.hasSignet !== !!transition.adrRid)) && (
                          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} />
                        )}
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Controller
                          id="transitionMrns"
                          name="transitionMrns"
                          defaultValue={mrnTags}
                          control={control}
                          render={() => (
                            <Autocomplete
                              multiple
                              limitTags={4}
                              freeSolo
                              filterSelectedOptions
                              value={mrnTags}
                              options={mrnTags}
                              defaultValue={mrnTags}
                              disabled={transitionFormDisabled}
                              getOptionLabel={(option) => option}
                              onChange={(event, data) => onChangeMrn(event, data)}
                              renderTags={(tagValue: string[], getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip key={option} label={option} {...getTagProps({ index })} />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t('pages.gateIn.form.mrn.label')}
                                  variant="outlined"
                                  disabled={transitionFormDisabled}
                                  error={!!errors.transitionMrns}
                                  helperText={errors.transitionMrns ? t('pages.gateIn.validations.mrn') : ' '}
                                  InputProps={{
                                    ...params.InputProps,
                                    className: classes.nhmInputAutocomplete,
                                  }}
                                  inputRef={mrnRef}
                                  className={classes.autocomplete}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Controller
                          id="nhmCodes"
                          name="nhmCodes"
                          defaultValue={transition?.nhmCodes}
                          control={control}
                          render={({ onChange, value }) => (
                            <Autocomplete
                              multiple
                              filterSelectedOptions
                              limitTags={6}
                              options={showNhmSelection ? nhmCodes : []}
                              value={value}
                              onChange={(event, newValue) => {
                                onChange(newValue)
                                onChangeNhmCodes(event, newValue)
                              }}
                              noOptionsText={showNhmSelection ? t('any.noOptions') : t('any.startTyping')}
                              getOptionLabel={(option) =>
                                (option as NhmCodeDto).code + ' - ' + (option as NhmCodeDto).nameCz ?? ''
                              }
                              disabled={transitionFormDisabled}
                              renderTags={(tagValue: NhmCodeDto[], getTagProps) =>
                                tagValue.map((option, index) => (
                                  <>
                                    <ConardTooltip title={option.code + ' - ' + option.nameCz}>
                                      <Chip
                                        classes={{ label: classes.chipLabel }}
                                        key={option.code}
                                        label={option.code + ' - ' + option.nameCz}
                                        {...getTagProps({ index })}
                                      />
                                    </ConardTooltip>
                                  </>
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t('pages.nhm.label')}
                                  variant="outlined"
                                  disabled={transitionFormDisabled}
                                  error={!!errors.nhmCodes}
                                  helperText={errors.nhmCodes ? t('pages.nhm.validation.label') : ' '}
                                  InputProps={{
                                    ...params.InputProps,
                                    className: classes.nhmInputAutocomplete,
                                  }}
                                  onChange={(event) => handleNhmSelection(event)}
                                  onBlur={() => setShowNhmSelection(false)}
                                  className={classes.autocomplete}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <span className={classes.datepickerLabel}>{t('pages.gateIn.form.transitionDatetime.label')}</span>
                    <ConardDateTimePicker
                      name="transitionDateTime"
                      control={control}
                      disabled={transitionFormDisabled}
                      helperText=" "
                    />
                  </Grid>
                  {configContext.storageAndDetentionDate && (
                    <Grid item md={6} xs={12}>
                      <span className={classes.datepickerLabel}>{t('pages.gateIn.form.storageEndDate.label')}</span>
                      <ConardDatePicker name="storageEndDate" control={control} disabled helperText={' '} />
                    </Grid>
                  )}
                  {configContext.storageAndDetentionDate && (
                    <Grid item md={6} xs={12}>
                      <span className={classes.datepickerLabel}>
                        {t('pages.gateIn.form.detentionEndDatetime.label')}
                      </span>
                      <ConardDateTimePicker
                        name="detentionEndDatetime"
                        control={control}
                        helperText={' '}
                        defaultValue={
                          !!transition.detentionEndDatetime ? new Date(transition.detentionEndDatetime) : null
                        }
                        disabled={transitionFormDisabled}
                      />
                    </Grid>
                  )}
                  {transition.direction !== TransitionDtoDirectionEnum.Out && (
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <ConardSwitch
                        id="lockdown"
                        name="lockdown"
                        checked={transition.lockdown}
                        control={control}
                        disabled={transitionFormDisabled || isLockdown}
                        onChange={(event) => changeSwitch(event, 'lockdown')}
                        checkedLabel={t('pages.gateIn.form.lockdown.checked')}
                        uncheckedLabel={t('pages.gateIn.form.lockdown.unchecked')}
                        helperText=" "
                      />
                    </Grid>
                  )}
                  {containerType === ContainerDtoContainerTypeEnum.ShippingContainer && (
                    <>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <ConardSwitch
                          id="weighted"
                          name="weighted"
                          checked={transition?.weighted}
                          onChange={(event) => changeSwitch(event, 'weighted')}
                          control={control}
                          disabled={transitionFormDisabled}
                          checkedLabel={t('pages.gateIn.form.weighted.checked')}
                          uncheckedLabel={t('pages.gateIn.form.weighted.unchecked')}
                          helperText=" "
                        />
                      </Grid>
                      {transition.weighted && configContext.vgm && (
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <ConardTextField
                            id="container.maxGross"
                            name="container.maxGross"
                            label={t('pages.gateIn.form.maxGross.label')}
                            defaultValue={transition.container.maxGross ?? ''}
                            value={transition.container.maxGross ?? ''}
                            control={control}
                            onChange={(event) => onChangeMaxGross(event.target.value)}
                            error={!!errors.container?.maxGross}
                            helperText={errors.container?.maxGross ? errors.container.maxGross.message : ' '}
                            disabled={transitionFormDisabled}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  {transition.repleted && (
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <ConardTextField
                        id="netWeight"
                        name="netWeight"
                        defaultValue={transition?.netWeight ?? 0}
                        value={transition?.netWeight ?? 0}
                        onChange={(event) => onChangeNetWeight(event.target.value)}
                        label={t('pages.gateIn.form.netWeight.label')}
                        control={control}
                        disabled={transitionFormDisabled}
                        error={!!errors.netWeight}
                        helperText={errors.netWeight ? errors.netWeight.message : ' '}
                      />
                    </Grid>
                  )}
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <ConardTextField
                      id="container.tarraWeight"
                      name="container.tarraWeight"
                      defaultValue={transition?.container.tarraWeight ?? ''}
                      value={transition?.container.tarraWeight ?? ''}
                      onChange={(event) => onChangeTarraWeight(event.target.value)}
                      label={t('pages.gateIn.form.tarraWeight.label')}
                      control={control}
                      disabled={transitionFormDisabled}
                      error={!!errors.container?.tarraWeight}
                      helperText={errors.container?.tarraWeight ? errors.container?.tarraWeight.message : ' '}
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <ConardTextField
                      id="grossWeight"
                      name="grossWeight"
                      label={t('pages.gateIn.form.grossWeight.label')}
                      defaultValue={(transition?.netWeight ?? 0) + (transition?.container.tarraWeight ?? 0)}
                      value={(transition?.netWeight ?? 0) + (transition?.container.tarraWeight ?? 0)}
                      control={control}
                      disabled
                      helperText={' '}
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <ConardTextField
                      id="note"
                      name="note"
                      label={t('pages.gateIn.form.note.label')}
                      control={control}
                      disabled={transitionFormDisabled}
                      error={!!errors.note}
                      helperText={errors.note ? errors.note.message : ' '}
                      defaultValue={transition?.note ?? ''}
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <ConardSelect
                      id="importExport"
                      name="importExport"
                      defaultValue={importExportValue}
                      value={importExportValue}
                      label={
                        t('pages.gateIn.form.importExport.import') + ' / ' + t('pages.gateIn.form.importExport.export')
                      }
                      disabled={transitionFormDisabled}
                      error={!!errors.importExport}
                      helperText={errors.importExport ? errors.importExport.message : ' '}
                      onChange={(event) => onChangeImportExport(event)}
                      control={control}
                    >
                      <MenuItem value={TransitionDtoImportExportEnum.None}>
                        {t('pages.gateIn.form.importExport.empty')}
                      </MenuItem>
                      <MenuItem value={TransitionDtoImportExportEnum.Export}>
                        {t('pages.gateIn.form.importExport.export')}
                      </MenuItem>
                      <MenuItem value={TransitionDtoImportExportEnum.Import}>
                        {t('pages.gateIn.form.importExport.import')}
                      </MenuItem>
                      <MenuItem value={TransitionDtoImportExportEnum.Positioning}>
                        {t('pages.gateIn.form.importExport.positioning')}
                      </MenuItem>
                    </ConardSelect>
                  </Grid>
                  {transitionMode !== TransitionMode.TRAIN &&
                    transition.receiptMethod === TransitionDtoReceiptMethodEnum.Road && (
                      <>
                        <Grid item xs={12} md={6}>
                          <ConardTextAutocomplete
                            id="driverShort"
                            name="driverShort"
                            optionType="DRIVER"
                            value={driver}
                            onChoose={onChooseDriver}
                            setValue={changeDriver}
                            control={control}
                            label={t('pages.gateIn.form.driver.label')}
                            helperText={errors.driverShort ? errors.driverShort.id?.message : ' '}
                            disabled={transitionFormDisabled}
                          />
                        </Grid>
                        {configContext.creatableCarrierFromTransitionForm ? (
                          <Grid item xs={12} md={6}>
                            <ConardTextAutocomplete
                              id="carrierShort"
                              name="carrierShort"
                              optionType="CARRIER"
                              value={carrier}
                              setValue={changeCarrier}
                              control={control}
                              label={t('pages.gateIn.form.carrier.label')}
                              helperText={errors.carrierShort ? errors.carrierShort.id?.message : ' '}
                              disabled={transitionFormDisabled}
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={12} md={6}>
                            <CarrierAutocomplete
                              carrier={carrier}
                              setCarrier={handleNewCarrier}
                              helperText={errors.carrierShort?.id?.message}
                              disabled={transitionFormDisabled}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                          <ConardTextAutocomplete
                            id="frontLicensePlate"
                            name="frontLicensePlate"
                            optionType="LICENSE_PLATE"
                            value={{ id: frontLicensePlate.id, name: frontLicensePlate.licensePlate }}
                            setValue={changeFrontLicensePlate}
                            control={control}
                            label={t('pages.gateIn.form.licensePlate.front')}
                            helperText={errors.frontLicensePlate ? errors.frontLicensePlate.id?.message : ' '}
                            disabled={transitionFormDisabled}
                          />
                        </Grid>
                        {transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer && (
                          <Grid item xs={12} md={6}>
                            <ConardTextAutocomplete
                              id="rearLicensePlate"
                              name="rearLicensePlate"
                              optionType="LICENSE_PLATE"
                              value={{ id: rearLicensePlate.id, name: rearLicensePlate.licensePlate }}
                              setValue={changeRearLicensePlate}
                              control={control}
                              label={t('pages.gateIn.form.licensePlate.rear')}
                              helperText={errors.rearLicensePlate ? errors.rearLicensePlate.id?.message : ' '}
                              disabled={transitionFormDisabled}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                  <Grid item xs={12}>
                    {configContext.heavyTested && containerType === ContainerDtoContainerTypeEnum.ShippingContainer && (
                      <FormGroup row className={classes.formGroup}>
                        <FormControlLabel
                          control={
                            <ConardCheckbox
                              id="container.heavyTested"
                              name="container.heavyTested"
                              color="primary"
                              disabled={transitionFormDisabled || heavyTestedMandatory}
                              defaultChecked={heavyTestedMandatory}
                              control={control}
                              onChange={(e, checked) => {
                                changeCheckbox('heavyTested', checked)
                              }}
                            />
                          }
                          label={t('pages.gateIn.form.heavyTested.label')}
                        />
                        <FormControlLabel
                          control={
                            <ConardCheckbox
                              id="container.innerWidth"
                              name="container.innerWidth"
                              color="primary"
                              control={control}
                              disabled={transitionFormDisabled}
                              onChange={(e, checked) => changeCheckbox('innerWidth', checked)}
                            />
                          }
                          label={t('pages.gateIn.form.innerWidth.label')}
                        />
                        <FormControlLabel
                          control={
                            <ConardCheckbox
                              id="container.lashingRings"
                              name="container.lashingRings"
                              color="primary"
                              disabled={transitionFormDisabled}
                              control={control}
                              onChange={(e, checked) => changeCheckbox('lashingRings', checked)}
                            />
                          }
                          label={t('pages.gateIn.form.lashingRings.label')}
                        />
                        <FormControlLabel
                          control={
                            <ConardCheckbox
                              id="container.woodenFloor"
                              name="container.woodenFloor"
                              color="primary"
                              disabled={transitionFormDisabled}
                              control={control}
                              onChange={(e, checked) => changeCheckbox('woodenFloor', checked)}
                            />
                          }
                          label={t('pages.gateIn.form.woodenFloor.label')}
                        />
                        <FormControlLabel
                          control={
                            <ConardCheckbox
                              id="container.flexiTanks"
                              name="container.flexiTanks"
                              color="primary"
                              disabled={transitionFormDisabled}
                              control={control}
                              onChange={(e, checked) => changeCheckbox('flexiTanks', checked)}
                            />
                          }
                          label={t('pages.gateIn.form.flexiTanks.label')}
                        />
                      </FormGroup>
                    )}
                  </Grid>
                  {configContext.heavyTested &&
                    transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer &&
                    (heavyTestedMandatory || transition.container.heavyTested) && (
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <ConardTextField
                          id="container.heavyTestedValue"
                          name="container.heavyTestedValue"
                          label={t('pages.gateIn.form.heavyTestedValue.label')}
                          type="number"
                          defaultValue={transition.container.heavyTestedValue ?? ''}
                          control={control}
                          disabled={transitionFormDisabled}
                          error={!!errors.container?.heavyTestedValue}
                          helperText={
                            errors.container?.heavyTestedValue ? errors.container?.heavyTestedValue.message : ' '
                          }
                        />
                      </Grid>
                    )}
                  {transitionMode === TransitionMode.SEARCH && transition?.direction === 'IN' && (
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                          <div className={classes.manipulationButton}>
                            <ConardButton
                              conardVariant="light"
                              disabled={transitionFormDisabled || transition?.manipulation === 0}
                              onClick={handleDecrement}
                            >
                              <RemoveIcon />
                            </ConardButton>
                          </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                          <ConardTextField
                            id="manipulation"
                            name="manipulation"
                            label={t('pages.gateIn.form.manipulation.label')}
                            InputLabelProps={{ shrink: true }}
                            control={control}
                            disabled
                            error={!!errors.manipulation}
                            helperText={errors.manipulation ? errors.manipulation.message : ' '}
                          />
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                          <div className={classes.manipulationButton}>
                            <ConardButton
                              conardVariant="light"
                              disabled={transitionFormDisabled}
                              onClick={handleIncrement}
                            >
                              <AddIcon />
                            </ConardButton>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {(new Set([TransitionMode.GATE_IN, TransitionMode.NOTIFICATION, TransitionMode.TRAIN]).has(
                    transitionMode
                  ) ||
                    (transitionMode === TransitionMode.SEARCH && transition.direction === 'IN')) && (
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Controller
                        id="transitionExtrasDto"
                        name="transitionExtrasDto"
                        defaultValue={transition?.extras}
                        control={control}
                        render={() => (
                          <Autocomplete
                            multiple
                            filterSelectedOptions
                            limitTags={6}
                            options={showAdditionalServicesSelection ? additionalServices : []}
                            defaultValue={transition?.extras ?? []}
                            noOptionsText={showAdditionalServicesSelection ? t('any.noOptions') : t('any.startTyping')}
                            getOptionLabel={(option) => (option as ExtraDto).name ?? ''}
                            disabled={transitionFormDisabled}
                            onChange={(event, data) => onChangeAdditionalServices(event, data)}
                            renderTags={(tagValue: ExtraDto[], getTagProps) =>
                              tagValue.map((option, index) => (
                                <>
                                  <ConardTooltip title={option.name ?? ''}>
                                    <Chip
                                      classes={{ label: classes.chipLabel }}
                                      key={option.name}
                                      label={option.name}
                                      {...getTagProps({ index })}
                                    />
                                  </ConardTooltip>
                                </>
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t('pages.transitionAdditionalServices.label')}
                                variant="outlined"
                                disabled={transitionFormDisabled}
                                error={!!errors.extras}
                                helperText={
                                  errors.extras ? t('pages.transitionAdditionalServices.validation.label') : ' '
                                }
                                InputProps={{
                                  ...params.InputProps,
                                  className: classes.nhmInputAutocomplete,
                                }}
                                onChange={(event) => handleAdditionalServicesSelection(event)}
                                onBlur={() => setShowAdditionalServicesSelection(false)}
                                className={classes.autocomplete}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
                {(containsNewDamageLogEntries ||
                  transitionMode !== TransitionMode.SEARCH ||
                  !transitionFormDisabled) && (
                  <ConardDamageLogBox
                    transitionFormDisabled={transitionFormDisabled}
                    transitionMode={transitionMode}
                    onOldDamageLogEntry={onOldDamageLogEntry}
                    onNewDamageLogEntryRequested={onNewDamageLogEntryRequested}
                    onDownloadPdf={handleDownloadPdf}
                    error={(errors['damageLogEntries'] as FieldError | undefined)?.message}
                    onExistingOldDamageLogs={(value) => setContainsAnyDamageLogEntries(value)}
                  />
                )}
                {configContext.repairShop &&
                  transition.direction !== TransitionDtoDirectionEnum.Out &&
                  (!transitionFormDisabled ||
                    (transition.repairShopStays && transition.repairShopStays?.length > 0)) && (
                    <ConardRepairShopBox
                      control={control}
                      isDisabled={transitionFormDisabled}
                      onAllRepairsFinished={() => changeSwitch(false, 'damaged')}
                      transitionMode={transitionMode}
                      transitionDateTime={transitionDateTime}
                      onLockdown={handleLockdown}
                    />
                  )}
                {!!transition.hasSignet && (
                  <Grid container direction="column" className={classes.damagePanelContainer}>
                    <Grid item>
                      <Typography variant="h5">{t('pages.gateIn.form.signetPhoto.label')}</Typography>
                    </Grid>
                    <Grid item className={classes.signetPhotoWrapper}>
                      {loadingSignetPhoto ? (
                        <CircularProgress
                          color="primary"
                          size={60}
                          thickness={6}
                          className={classes.circularProgress}
                        />
                      ) : !signetPhoto.signetPhoto && transitionFormDisabled ? (
                        <Typography>{t('pages.gateIn.form.signetPhoto.notPresent')}</Typography>
                      ) : !signetPhoto.signetPhoto ? (
                        <div className={classes.signetPhotoDiv} onClick={handleAddSignetPhoto}>
                          <AddAPhoto />
                        </div>
                      ) : (
                        <img
                          src={signetPhoto.signetPhoto}
                          alt="signet photo"
                          className={classes.signetPhoto}
                          onClick={() => setShowSignetPhotoDetail(true)}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
                {new Set([TransitionMode.GATE_OUT, TransitionMode.SEARCH]).has(transitionMode) && (
                  <>
                    <ConardStore
                      transitionPositionDisabled={transitionPositionDisabled}
                      sectorLetterDisabled={transitionFormDisabled}
                      sectorLetterValue={transition?.sectorLetter}
                      onChangeSectorLetter={(event) => onChangeSectorLetter(event)}
                      errorSectorLetter={!!errors.sectorLetter}
                      helperTextSectorLetter={errors.sectorLetter ? errors.sectorLetter.message : ' '}
                      controlSectorLetter={control}
                      decrementColumnButtonDisabled={transitionPositionDisabled || transition?.sectorColumn === 1}
                      incrementColumnButtonDisabled={
                        transitionPositionDisabled ||
                        transition?.sectorColumn === configContext.coordinates.maxSectorColumn
                      }
                      onColumnPositionDecrement={() => handlePositionDecrement('sectorColumn')}
                      controlSectorColumn={control}
                      sectorColumnValue={transition.sectorColumn}
                      errorSectorColumn={!!errors.sectorColumn}
                      helperTextSectorColumn={errors.sectorColumn ? errors.sectorColumn.message : ' '}
                      onColumnPositionIncrement={() => handlePositionIncrement('sectorColumn')}
                      decrementRowButtonDisabled={transitionPositionDisabled || transition?.sectorRow === 1}
                      onRowPositionDecrement={() => handlePositionDecrement('sectorRow')}
                      controlSectorRow={control}
                      sectorRowValue={transition.sectorRow}
                      errorSectorRow={!!errors.sectorRow}
                      helperTextSectorRow={errors.sectorRow ? errors.sectorRow.message : ' '}
                      incrementRowButtonDisabled={
                        transitionPositionDisabled || transition?.sectorRow === configContext.coordinates.maxSectorRow
                      }
                      onRowPositionIncrement={() => handlePositionIncrement('sectorRow')}
                      decrementFloorButtonDisabled={transitionPositionDisabled || transition?.sectorFloor === 1}
                      onFloorPositionDecrement={() => handlePositionDecrement('sectorFloor')}
                      controlSectorFloor={control}
                      sectorFloorValue={transition.sectorFloor}
                      errorSectorFloor={!!errors.sectorFloor}
                      helperTextSectorFloor={errors.sectorFloor ? errors.sectorFloor.message : ' '}
                      incrementFloorButtonDisabled={
                        transitionPositionDisabled ||
                        transition?.sectorFloor === configContext.coordinates.maxSectorFloor
                      }
                      onFloorPositionIncrement={() => handlePositionIncrement('sectorFloor')}
                    />
                    {transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer &&
                      (transition.container.shipowner?.id === maerskShipownerId ||
                        transition.container.shipowner?.id === mscShipownerId) &&
                      auth.hasOneOfRoles(new Set([...normalAndRoadDispatcher, UserDtoRoleEnum.Manager])) &&
                      (transition.containerState === TransitionDtoContainerStateEnum.FinishedGateIn ||
                        transition.containerState === TransitionDtoContainerStateEnum.FinishedGateOut) && (
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          className={classes.damagePanelContainer}
                        >
                          <Grid item>
                            <Typography variant="h5">{t('pages.gateIn.headers.codeco')}</Typography>
                          </Grid>
                          <Grid item className={classes.damageContent}>
                            {latestCodeco?.processed
                              ? t('pages.gateIn.headers.codecoSent') +
                                format(new Date(latestCodeco?.processed), 'dd.MM.yyyy HH:mm')
                              : t('pages.gateIn.headers.codecoNotSent')}
                          </Grid>
                          {!!latestCodeco && auth.getRole() !== UserDtoRoleEnum.Carrier && (
                            <Grid item className={classes.codecoButton}>
                              <ConardButton
                                conardVariant="light"
                                onClick={() => processedFileService.handleDownload(latestCodeco)}
                              >
                                {t('any.buttons.download')}
                              </ConardButton>
                            </Grid>
                          )}
                          <Grid item>
                            <ConardButton
                              conardVariant="light"
                              onClick={() => {
                                resendCodeco()
                              }}
                            >
                              {t('any.buttons.resendCodeco')}
                            </ConardButton>
                          </Grid>
                        </Grid>
                      )}
                    {configContext.customsClearance && auth.getRole() !== UserDtoRoleEnum.Carrier && (
                      <ConardTransitionCustomsInfoBox transitionMode={transitionMode} transition={transition} />
                    )}
                    {transition.containerState === TransitionDtoContainerStateEnum.PlannedGateIn &&
                      transition.id !== undefined &&
                      configContext.instantGreenPass &&
                      auth.getRole() !== UserDtoRoleEnum.Carrier && (
                        <ConardInstantGreenBox
                          transitionId={transition.id}
                          containerState={transition.containerState}
                        />
                      )}
                    {configContext.transitionPdfExport && auth.getRole() !== UserDtoRoleEnum.Carrier && (
                      <ConardTransitionFormPdfExportBox transitionId={transition.id ?? -1} />
                    )}
                    {configContext.vgm &&
                      transition.weighted &&
                      transition.direction !== TransitionDtoDirectionEnum.Out && (
                        <ConardTransitionFormVgmBox transitionId={transition.id ?? -1} hasVgm={!!transition.vgm} />
                      )}
                  </>
                )}
              </Grid>
            </Grid>
            <div className={classes.controls}>
              <ButtonsForTransitionForm
                gateInBrno={gateInBrno}
                gateInMosnov={gateInMosnov}
                gateOutBrno={gateOutBrno}
                gateOutMosnov={gateOutMosnov}
                isPlannedTransitionNotification={isPlannedTransitionNotification}
                normalAndRoadDispatcher={normalAndRoadDispatcher}
                notification={notification}
                onCancel={onCancel}
                onClickBack={() => history.goBack()}
                onShowDeleteDialog={() => setShowDeleteDialog(true)}
                onFinishGo={() => onFinishGo()}
                onProcessFalse={() => handleProcess(false)}
                onProcessTrue={() => handleProcess(true)}
                onClickEdit={UserDtoRoleEnum.Operator === auth.getRole() ? handlePositionDisabled : handleDisabled}
                onHandleParkingLot={handleParkingLot}
                onStoreFalse={() => setStore(false)}
                onStoreTrue={() => setStore(true)}
                onTakeOutGateOut={() => setTakeout(true)}
                parkingDisabled={parkingDisabled}
                transition={transition}
                transitionFormDisabled={transitionFormDisabled}
                transitionMode={transitionMode}
                transitionPositionDisabled={transitionPositionDisabled}
                transitionSaveDisabled={transitionSaveDisabled}
              />
            </div>
          </>
        )}

        <ConardPhotographer
          title={t('pages.damageLogManagement.dialog.iluPhoto.title')}
          open={showPhotographer}
          onClose={() => {
            setShowPhotographer(false)
            setShowForm(true)
          }}
          onAccept={onIluPhotoAccepted}
        />
        <TransitionWrongIluDialog
          onSubmit={onSubmit}
          transition={{
            ...transition,
            ...control.getValues(),
            netWeight: transition.netWeight ? +transition.netWeight : undefined,
            container: {
              ...transition?.container,
              tarraWeight: Number(control.getValues().container?.tarraWeight),
              iluCode: control.getValues().container?.iluCode,
              heavyTestedValue: control.getValues().container?.heavyTestedValue,
            },
          }}
          showDialog={showWrongIluDialog}
          setShowDialog={setShowWrongIluDialog}
        />
      </form>

      <Dialog open={showCodecoDialog}>
        <DialogTitle>{t('pages.searchTransition.dialog.codeco.title')}</DialogTitle>
        <DialogContent className={classes.codecoDialog}>
          <CheckCircleOutlineRoundedIcon color="primary" className={classes.codecoDialogIcon} />
          <Typography>{t('pages.searchTransition.dialog.codeco.content')}</Typography>
        </DialogContent>
        <DialogActions>
          <ConardButton conardVariant="dark" onClick={() => setShowCodecoDialog(false)}>
            {t('any.buttons.ok')}
          </ConardButton>
        </DialogActions>
      </Dialog>
      <TransitionDeleteDialog
        showDialog={showDeleteDialog}
        setShowDialog={setShowDeleteDialog}
        data={{
          id: transition.id,
          direction: transition.direction,
          iluCode: transition.container.iluCode,
          transitionDateTime: transition.transitionDateTime ?? '',
        }}
        onDelete={onDelete}
      />
      <Dialog open={showZeroDamageEntryDialog}>
        <DialogTitle>{t('pages.damageLogManagement.dialog.zeroDamageLogs.title')}</DialogTitle>
        <DialogContent className={classes.codecoDialog}>
          {containerType === ContainerDtoContainerTypeEnum.ShippingContainer ? (
            <ContainerDamagedIcon className={classes.damageIcon} />
          ) : (
            <SemitrailerDamagedIcon className={classes.damageIcon} />
          )}
        </DialogContent>
        <DialogActions>
          <ConardButton conardVariant="dark" onClick={() => setShowZeroDamageEntryDialog(false)}>
            {t('any.buttons.ok')}
          </ConardButton>
        </DialogActions>
      </Dialog>

      <ConardPhotographer
        title={t('pages.gateIn.form.signetValue.label')}
        open={showSignetPhotographer}
        onClose={() => setShowSignetPhotographer(false)}
        onAccept={acceptNewSignetPhoto}
      />
      <SignetPhotoDetail
        signetPhoto={signetPhoto.signetPhoto}
        handleAddPhoto={handleAddSignetPhoto}
        handleDeletePhoto={handleDeleteSignetPhoto}
        showPhotoDetail={showSignetPhotoDetail}
        handleShowPhotoDetail={() => setShowSignetPhotoDetail(!showSignetPhotoDetail)}
        disabled={transitionFormDisabled}
      />
    </>
  )
}

export default ConardTransitionForm
